import { Component, Input, OnInit } from '@angular/core';
import { ScopeChangeResponse } from '../../../interface/scope-change-request';
import { SummaryAboutProject, SummaryScopeChange } from '../../../interface/summary-scope-change';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { UtilService } from 'src/app/shared/util.service';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';

@Component({
  selector: 'app-analysis-info',
  templateUrl: './analysis-info.component.html',
  styleUrls: ['./analysis-info.component.scss']
})
export class AnalysisInfoComponent implements OnInit {
  private _scopeChange: ScopeChangeResponse | null;
  @Input() scopeChangeId: number;
  @Input() project!:ProjectByIdResponseDto;

  summaryScopeChange: SummaryScopeChange | undefined = undefined
  projectSummary: SummaryAboutProject | undefined = undefined
  scopeChangeAttachments: NzUploadFile[];

  @Input() set scopeChange(value: ScopeChangeResponse | null) {
    this._scopeChange = value;
    this.createSummaryForScopeAndProject();
  }

  constructor(
    private readonly attachmentsService: AttachmentsService,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.createSummaryForScopeAndProject();
    this.getAttachments();
  }



  createSummaryForScopeAndProject() {
    this.getScopeSummary();
    this.getProjectSummary();
  }

  getProjectSummary() {
    this.projectSummary = {
      projectName: this.project?.name || "",
      projectCode: this._scopeChange?.code || "",
      projectNumber: this._scopeChange?.number || "",
      projectClassification: this._scopeChange?.classificationType || "",
      projectDescription: this._scopeChange?.comments || "",
      localization: this.getLocalization(this._scopeChange),
      areaRequest: this._scopeChange?.area?.description || "",
      responsible: this._scopeChange?.userLegalRepresentative?.name || ""
    }
  }

  private getLocalization(_scopeChange: ScopeChangeResponse | null): string {
    let localization = "";

    if (_scopeChange && _scopeChange.complex && _scopeChange.region) {
      localization = `${_scopeChange.region.description} / ${_scopeChange.complex.description}`
      if (_scopeChange.operationalUnit) {
        localization += ` / ${_scopeChange.operationalUnit.description}`
      }
    }

    return localization;
  }

  getScopeSummary() {
    this.summaryScopeChange = {
      change: this._scopeChange?.scopeChangeReasons || [],
      description: this._scopeChange?.commentsAboutChange || ""
    }
  }

  getAttachments() {
    this.attachmentsService.getAttachmentsByEntityIdEntityNameAndContextInfo(
      this.scopeChangeId,
      "SCOPE_CHANGE",
      ""
    ).subscribe({
      next: (attachments) => {
        this.scopeChangeAttachments = this.buildNzUploadFileArrayFromResponse(attachments);
      }
    })
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

}
