import { Component, Input, OnInit } from '@angular/core';

import { ScopeChangeResponse } from '../../../interface/scope-change-request';

import { ProjectV2ViewResponseDto } from 'src/app/shared/dto/project-v2-view-response.dto';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { ScopeChangeService } from '../../../service/scope-change/scope-change.service';

@Component({
  selector: 'app-analysis-history',
  templateUrl: './analysis-history.component.html',
  styleUrls: ['./analysis-history.component.scss']
})
export class AnalysisHistoryComponent implements OnInit {
  @Input() scopeChangeId!: number;
  @Input() scopeChangeHistory!: any[];

  requisitions: any;
  
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };

  scopeChange;

  constructor(
    private readonly scopeChangeService: ScopeChangeService,
  ) { }

  ngOnInit() {
    this.scopeChange = this.scopeChangeHistory
  }

}