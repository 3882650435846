<div class="app-select-files-list no__attachments mb-24" *ngIf="isAttachments && hasTouchedSetAttachment">
    <ng-container *ngTemplateOutlet="messageTemplate; context: { messageText: hasAttachmentText }"> </ng-container>
</div>

<div class="app-select-files-list" [class.no__attachments]="!isAttachments || !hasTouchedSetAttachment">
    @if (isAttachments && hasTouchedSetAttachment) {
        @if (selectedAttachments.length < 5) {
            <span class="ft-grey">{{'chat-ia.select-files.files-list.title' | translate}}: </span>
        }

        @if (selectedAttachments.length >= 5 ) {
            <span class="ft-red">{{'chat-ia.select-files.files-list.limit' | translate}} </span>
        }

        <div class="card flex justify-content-center">
            <div class="flex flex-column gap-2">
                <div *ngFor="let file of attachments" class="field-checkbox">
                    <p-checkbox
                        [(ngModel)]="selectedAttachments"
                        [label]="file.attachment ? file.attachment.name : file.name"
                        name="group"
                        [value]="file"
                        (ngModelChange)="onChangeAttachments()"/>
                </div>
            </div>
        </div>

        <div class="footer">
            <span (click)="openFileSelection()" >{{'chat-ia.select-files.files-list.select' | translate}}</span>
            <app-button-icon-primary
                [id]="'btn_send_files'"
                [text]="'Confirmar'"
                [disabledBtn]="selectedAttachments.length === 0 || selectedAttachments.length > 5"
                (click)="sendFilesSelected()"
            >
            </app-button-icon-primary>
        </div>
    }
    @if (!isAttachments && hasTouchedSetAttachment) {
        <ng-container *ngTemplateOutlet="messageTemplate; context: { messageText: notFoundFileText }"> </ng-container>
        <app-button-icon-primary [id]="'btn_select_files'" [text]="'Selecionar documentos'" (click)="openFileSelection()"> </app-button-icon-primary>

    }

    @if (showButtonAddDocument() && !hasTouchedSetAttachment) {
        <div class="btn__selected__file" (click)="onChangeCallAction()">
            <img src="assets/icons/icon_add_file_purple32.svg"/>
            <p>{{'chat-ia.select-files.files-list.add' | translate}}</p>
        </div>
    }
</div>

<!-- TEMPLATE  -->
<ng-template #messageTemplate let-messageText="messageText">
    <div class="fake__menssage">
        <div class="icon">
            <img src="assets/icons/speechbubble_purple32.svg"/>
            <img class="particles" src="assets/icons/icon_ai_particles_dark_purple24.svg"/>
        </div>
        <span [innerHTML]="messageText"></span>
    </div>
</ng-template>
