import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "src/app/shared/services/user.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ScopeChangeService } from "../../../service/scope-change/scope-change.service";
import { ScopeChangeConfirmationRequest, ScopeChangeResponse } from "../../../interface/scope-change-request";
import moment from "moment";
import { MessageService } from "src/app/shared/common/services/message.service";
import { Router } from "@angular/router";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { buildRoute, RoutesEnum } from "src/app/shared/enum/routes.enum";
import {UtilService} from "../../../../../shared/util.service";

@Component({
  selector: 'app-requesting-area-confirmation',
  templateUrl: './requesting-area-confirmation.component.html',
  styleUrls: ['./requesting-area-confirmation.component.scss']
})
export class RequestingAreaConfirmationComponent implements OnInit {
  @Input() scopeChange: ScopeChangeResponse;
  @Input() confirmScopeChange?: boolean = true;
  private infoColor: 'black' | 'yellow' | 'red' = 'black';

  daysToDeadline: number;
  form: FormGroup;
  isUserDemandant: boolean;
  isExpired: boolean = false;
  confirmationInfo: any[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: NzModalService,
    private readonly scopeChangeService: ScopeChangeService,
    private readonly util: UtilService,
    private readonly messageService: MessageService,
    private readonly modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isUserDemandant = this.util.getAuthorization('insert', 'CONFIRMSCOPECHANGE')
    if (this.scopeChange.confirmDeadLine) {
      let today = moment().startOf('day');
      let deadline = moment(this.scopeChange.confirmDeadLine)

      this.daysToDeadline = Math.round(moment.duration((deadline as any) - (today as any)).asDays());
    }

    this.form = this.formBuilder.group({
      finalComments: [null],
    });

    if (this.isConcludedOrCancelled()) {
      this.fillConfirmationInfo();
    }
  }

  onChangeFinalComments(event: string): void {
    this.form.controls['finalComments'].setValue(event);
  }

  fillConfirmationInfo(): void {
    this.confirmationInfo = [
      {
        key: 'projects.scope-change.is-change-accepted',
        value: this.scopeChange.status == "CON" ? 'Sim' : 'Não'
      },
      {
        key: 'projects.scope-change.confirm-user',
        value: this.scopeChange.confirmUser?.name
      },
      {
        key: 'projects.scope-change.confirm-date',
        value: this.scopeChange.finalSubmissionDate ?
          moment(this.scopeChange.finalSubmissionDate, "YYYY-MM-DD").format("DD/MM/YYYY") : ""
      },
      {
        key: 'projects.scope-change.final-comments',
        value: this.scopeChange.confirmComments ? this.scopeChange.confirmComments : "Não há comentários"
      }
    ]
  }

  isExistDisciplinesEan(): boolean {
    return this.scopeChange?.disciplineAssessments.filter(elem => elem.status == 'EAN').length == 0
  }

  public isConcludedOrCancelled(): boolean {
    return this.scopeChange.status == "CON" || this.scopeChange.status == "CAN"
  }

  public getDeadlineDate(): string {
    if (this.scopeChange.confirmDeadLine) {
      return moment(this.scopeChange.confirmDeadLine, "YYYY-MM-DD").format("DD/MM/YYYY");
    } else return ""
  }

  public getDateColorClass(): string {
    if (this.infoColor === 'black') {
      return 'deadline_value';
    } else if (this.infoColor === 'yellow') {
      return 'deadline_value yellow'
    } else {
      return 'deadline_value red';
    }
  }

  public getBannerText(): string {
    let passedDays = 120 - this.daysToDeadline;

    if (this.isUserDemandant) {
      if (passedDays < 15) {
        return `Responda a confirmação em até ${this.daysToDeadline} dia(s) para evitar o cancelamento da requisição.`;
      } else if (passedDays < 120) {
        if (passedDays > 105) this.infoColor = "red";
        else this.infoColor = "yellow";

        return `O prazo inicial de confirmação da mudança de escopo expirou. Responda a confirmação em até ${this.daysToDeadline} dia(s) para evitar o cancelamento da requisição.`;
      } else {
        this.infoColor = "red";
        this.isExpired = true;
        return 'Requisição cancelada por falta de confirmação da mudança dentro do prazo determinado.'
      }
    } else {
      return 'A área solicitante ainda não respondeu à confirmação da mudança de escopo';
    }
  }

  public scopeChangeConclusion(): void {
    this.modalService.confirm({
      ...(this.buildCustomMessage(false)),
      nzOnOk: () => {
        this.callConfirmationEndpoint(false);
      }
    })
  }

  public openCancelationModal(): void {
    this.modalService.confirm({
      ...(this.buildCustomMessage(true)),
      nzOnOk: () => {
        this.callConfirmationEndpoint(true);
      }
    });
  }

  buildCustomMessage(cancelled: boolean): any {
    return {
      nzTitle: `Deseja mesmo ${cancelled ? 'cancelar?' : 'confirmar?'}`,
      nzContent:'Essa ação não poderá ser desfeita.',
      nzOkText: cancelled ? 'Cancelar mudança de escopo' : 'Confirmar mudança',
      nzOkType: 'primary',
      nzOkDanger: cancelled,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false
    };
  }

  public callConfirmationEndpoint(cancelled: boolean) {
    const request: ScopeChangeConfirmationRequest = {
      id: this.scopeChange.id,
      comments: this.form.value['finalComments'],
      isCancelled: cancelled
    };

    this.scopeChangeService.scopeChangeConfirmation(request).subscribe({
      next: () => {

        const route = buildRoute(RoutesEnum.PROJECT_BY_ID, {
          projectId: `${this.scopeChange.projectId}`
        });

        const modal = this.modal.create<ConfirmationModalComponent>({
          nzTitle: '',
          nzClosable: false,
          nzWidth: 555,
          nzCentered: true,
          nzMaskClosable: false,
          nzKeyboard: false,
          nzContent: ConfirmationModalComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzFooter: null,
        });

        const instance = modal.getContentComponent();
        instance.title = `O ${cancelled ? 'cancelamento' : 'aceite'} da requisição da mudança de escopo do projeto
        for realizado com sucesso.`
        instance.buttonAction = () => {
          this.router.navigate([route]);
        }

      },
      error: (err) => {
        let messageToShow = `Erro ao realizar ${cancelled ? 'cancelamento' : 'confirmação'} da mudança de escopo`;
        if (err.error && err.error.message) {
          messageToShow = err.error.message;
        } else if (err.error && err.error.errors.details) {
          messageToShow = err.error.errors.details;
        }

        this.messageService.showErrorMessage(messageToShow);
      }
    })
  }
}
