import { createAction, props } from '@ngrx/store';
import { IAttachment } from './pages.model';

export const setAttachments = createAction(
  '[ChatAI] Set Attachment',
  props<{ attachments: IAttachment[] }>()
);

export const setIsReloadChat = createAction(
  '[ChatAI] Set IsReloadChat',
  props<{ isReloadChat: boolean }>()
);

export const setIsChatOpen = createAction(
  '[ChatAI] Set IsChatOpen',
  props<{ isChatOpen: boolean }>()
);

