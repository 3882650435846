<div class="app-publish-final-form">
    <div>
        <h2 class="h2-title"> Detalhes sobre a requisição </h2>
    </div>

    <div class="line__form multipleSelectBig">
        <app-select-multiple-tag
            [id]="'select-multiple-tag-detail'"
            [name]="'Quais são os impactos associados à mudança de escopo?'"
            errorMessage=""
            [maxTagCount]="2"
            [status]="checkInputForm('scopeChangeImpacts')"
            [listOfOption]="scopeChangeImpactsList"
            [controls]="form.get('scopeChangeImpacts')"
            (changeValue)="onChangeScopeChangeImpacts($event)"
            [nzBorderless]="true">
        </app-select-multiple-tag>
    </div>

    <div class="line__form">
        <app-select-simple
            [id]="'select-simple'"
            [name]="'Qual a estimativa de impacto em tempo da mudança de escopo?'"
            [status]="checkInputForm('impactEstimated')"
            [listOfOption]="estimateList"
            errorMessage=""
            [controls]="form.get('impactEstimated')"
            (changeValue)="onChangeScopeImpactEstimated($event)">
        </app-select-simple>
    </div>

    <div class="line__form">
        <app-input-textarea-count
            [id]="'input-text-area-detail'"
            [name]="'Descrição dos impactos associados'"
            errorMessage=""
            [status]="checkInputForm('impactDescription')"
            [styleResize]="'none'"
            [required]="false"
            [controls]="form.get('impactDescription')"
            [maxCharacterCount]="2000"
            (changeValue)="onChangeDescriptionDetail($event)">
        </app-input-textarea-count>
    </div>

    <div class="line__form">
        <app-upload-files
            #uploadFiles
            title="{{ 'commons.files' | translate }} (opcional)"
            [attachmentsId]="1"
            entityName="SCOPE_CHANGE_FINAL"
            [disabledBlock]="false"
            [instantUpload]="true">
        </app-upload-files>
    </div>

</div>
