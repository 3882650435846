<div class="app-generic-modal-search">
    <i class="pi pi-search ft__size12" [class.has__value]="searchValue.length > 0" (click)="modalSearch.toggle($event)"></i>

    <p-overlayPanel #modalSearch>
        <div class="ant-table-filter-dropdown">
            <div class="search__box">
                <nz-input-group [nzPrefix]="suffixIconSearch">
                    <input type="text" nz-input autofocus placeholder="Pesquisar" class="inputGroup"
                    style="font-size: 12px !important;" [(ngModel)]="searchValue" (keydown)="callSendAction($event)" />
                </nz-input-group>

                <ng-template #suffixIconSearch>
                    <img class="close__icon" src="/assets/icons/icon_search_green.svg" alt="Close icon" />
                </ng-template>

                <div class="buttons__box">
                    <button class="clean__button" nz-button nzSize="small" (click)="resetFilter()"> Limpar busca </button>
                    <button class="search__button" nz-button nzSize="small" (click)="searchFilter()"> Buscar </button>
                </div>
            </div>
        </div>
    </p-overlayPanel>
</div>
