<div class="app-detail-about-request-container">
    <div>
        <h2 class="h2-title"> Detalhes sobre a requisição </h2>
    </div>

    <div class="line__form">
        <app-select-multiple-tag
            [id]="'select-multiple-tag-detail'"
            [name]="'Quais as mudanças no escopo do projeto?'"
            errorMessage=""
            [maxTagCount]="3"
            [status]="checkInputForm('scopeCategory')"
            [listOfOption]="scopeChangeList"
            [controls]="form.get('scopeCategory')"
            (changeValue)="onChangeScopeCategory($event)">
        </app-select-multiple-tag>
    </div>

    <div class="line__form">
        <app-input-textarea
            [id]="'input-text-area-detail'"
            [name]="'Observações sobre as mudanças de escopo'"
            errorMessage=""
            [styleResize]="'none'"
            [placeholder]="'Informe'"
            [status]="checkInputForm('descriptionDetail')"
            [required]="true"
            [controls]="form.get('descriptionDetail')"
            (changeValue)="onChangeDescriptionDetail($event)">
        </app-input-textarea>
    </div>



</div>

