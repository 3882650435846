import { createReducer, on } from '@ngrx/store';
import { setAttachments, setIsChatOpen, setIsReloadChat } from './chat-ai.actions';
import { AttachmentsState } from './chat-ai.state.model';

const initialState: AttachmentsState = {
  attachments: [],
  isChatOpen: false,
  isReloadChat: false
};

const _attachmentReducer = createReducer(
  initialState,
  on(setAttachments, (state, { attachments }) => ({ ...state, attachments })),
  on(setIsChatOpen, (state, { isChatOpen }) => ({ ...state, isChatOpen })),
  on(setIsReloadChat, (state, { isReloadChat }) => ({ ...state, isReloadChat }))
);


export function AttachmentReducer(state, action) {
  return _attachmentReducer(state, action);
}
