<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="app-history-request-review-container">
  <div class="scope">

    <div class="scope__items" *ngFor="let history of scopeChangesHistory; let last = last">

      <div class="scope__items__item" id="historicoEscopo">

        <!-- Data do Pedido -->
        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.scope-change.history-request-reviews.request-date" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ history.registrationDate | date : "dd/MM/yyyy" }}
          </p>
        </div>

        <!-- Data da Resposta -->
        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.scope-change.history-request-reviews.response-date" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ history.dateResponse ? (history.dateResponse | date : "dd/MM/yyyy") : null }}
          </p>
        </div>

        <!-- Solicitado por -->
        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.scope-change.history-request-reviews.requested-by" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ history.user.name }}
          </p>
        </div>

        <!-- Respondido por -->
        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.scope-change.history-request-reviews.answered-by" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ history.userResponse ? history.userResponse.name : null }}
          </p>
        </div>

        <!-- Motivos para revisao por -->
        <div class="scope__items__item__row" *ngIf="history.subProjectReviewReasons.length > 0">
          <p class="scope__items__item__row__title">
            <!-- Mudanças -->
            {{ "projects.scope-change.changes" | translate }}
          </p>
          <ul>
            <li class="scope__items__item__row__description" *ngFor="let reason of history.subProjectReviewReasons">
              <span class="bullet">&#x2022;</span>
              {{reason.reason.description}}
            </li>
          </ul>
        </div>

        <!-- Descricao -->
        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.scope-change.history-request-reviews.description" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ history.comments }}
          </p>
        </div>
        
        <div *ngIf="!last" class="hr-format">
          <nz-divider class="hr-format"></nz-divider>
        </div>

      </div>

    </div>

  </div>
</div>
