import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ScopeChangeResponse, ScopeChangeSendReviewToAnalysis, SubProjectReviewList } from '../../interface/scope-change-request';
import { ActivatedRoute, Router } from '@angular/router';
import { ScopeChangeService } from '../../service/scope-change/scope-change.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user.service';
import moment from 'moment';
import { badgeConfig } from 'src/app/shared/components/badge-chips/badge-chips.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequestReviewModalComponent } from '../request-review-modal/request-review-modal.component';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { of, switchMap } from 'rxjs';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-request-review',
  templateUrl: './request-review.component.html',
  styleUrls: ['./request-review.component.scss']
})
export class RequestReviewComponent implements OnInit,OnChanges {
  component: { deadline: string; };
  [x: string]: {};

  @Input() scopeChange: ScopeChangeResponse;
  @Input() scopeChangeId: number;
  @Input() scopeChangeName: string;
  @Input() projectId: number;
  @Input() showActionButtons: boolean;
  @Input() resolvReview: boolean;
  @Input() editRequisition: boolean;

  @Output() isReloadData = new EventEmitter<boolean>();

  subProjectReview: SubProjectReviewList;
  badgeMessage: string = '';
  badgeConfig: badgeConfig = {badgeColor: 'warning', badgeIcon: '', badgeText: ''};


  constructor(
    private messageService: MessageService,
    private router: Router,
    private scopeChangeService: ScopeChangeService,
    private translateService: TranslateService,
    private userService: UserService,
    private readonly modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private projectService: ProjectsV2Service,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getReviewEAN();
    this.calculateDaysRemaining();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getReviewEAN();
  }

  private getReviewEAN() {
    let review = this.scopeChange?.subProjectReviewList?.filter((item) => item.status === 'EAN');
    if (review) {
      this.subProjectReview = review[0];
    }
  }

  goToReview(): void {
    const params = {
      scopeChangeName: this.scopeChangeName
    }
    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.projectId
    });

    const routeEdit = buildRoute(RoutesEnum.SCOPE_CHANGE_REVIEW, {
      scopeId: this.scopeChangeId,
    });

    this.router.navigate([`${routeProject}/requisicoes/${routeEdit}`], { queryParams: params });
  }

  onCancelReview(): void {
    let request: ScopeChangeSendReviewToAnalysis = { idScopeChange: this.scopeChangeId }
    let id = this.scopeChange.subProjectReviewList[0].id;
    this.scopeChangeService.patchCancelSubProjectReview(id, request)
    .subscribe({
      next: (response) => {
        this.messageService.showSucessMessage('O pedido de revisão foi cancelado.')
        this.isReloadData.emit(true);
      },
      error: (err) => {
        this.messageService.showErrorMessage('Ocorreu um erro ao cancelar pedido de revisão.')
      }
    })
  }

  isDemandant(): boolean {
    return this.userService.isRoleLoggedUser('ROLE_DEMANDANTE');
  }

  calculateDaysRemaining() {
    const today = moment();
    const target = moment(this.subProjectReview?.deadline);
    let daysRemaining = target.diff(today, 'days');

    if (daysRemaining <= 105 && daysRemaining >= 0) {
      this.setConfigBadgeAfter15days(daysRemaining);
    }

    if (daysRemaining < 0) {
      this.setConfigBadgeAfter120days(daysRemaining);
    }

    if (this.scopeChange.status === 'CAN') {
      this.setConfigBadgeAfter120days(daysRemaining);
    }
  }

  setConfigBadgeAfter15days(days: number): void {
    let textBage = this.translateService.instant('projects.scope-change.request-review.badge-expired-warning-1') + ` ${days} ` +
    this.translateService.instant('projects.scope-change.request-review.badge-expired-warning-2');
    this.badgeConfig.badgeIcon = 'icon_warning_neutral';
    this.badgeConfig.badgeColor = 'warning';
    this.badgeConfig.badgeText = textBage;
  }

  setConfigBadgeAfter120days(days: number): void {
    let textBage = this.translateService.instant('projects.scope-change.request-review.badge-expired-cancel');
    this.badgeConfig.badgeIcon = 'icon_warning_alt';
    this.badgeConfig.badgeColor = 'info';
    this.badgeConfig.badgeText = textBage;
  }

  onEditReview(subProjectReview: SubProjectReviewList): void {
    const modal = this.modal.create<RequestReviewModalComponent>({
      nzTitle: 'Editar revisão da requisição',
      nzClosable: true,
      nzWidth: 600,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: RequestReviewModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });
    const instance = modal.getContentComponent();
    instance.scopeChangeId = this.scopeChangeId;
    instance.requestReview = { id: subProjectReview.id, details: subProjectReview.comments };
    modal.afterClose.subscribe((success: boolean) => {
      if (success) {
        this.isReloadData.emit(true);
      }
    })
  }

}
