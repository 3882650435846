<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
  <section class="app-scope-change-analysis sl__simpleLayout">
    <header>
      <div class="sl__simpleLayout__header__content">
        <button
          type="button"
          id="button-back"
          class="sl__simpleLayout__header__buttonBack"
          (click)="goBack()"
        >
          <img src="assets/icons/icon_arrow-back.svg" alt="Voltar" />
        </button>
        <h1>{{ "projects.scope-change.scope-change-title" | translate }}</h1>
      </div>

      <div *ngIf="!blockScopeChange" class="sl__simpleLayout__header__nav">
        <app-button-icon-secondary
          *ngIf="this.anarequisition && existSubProjectReviewList()"
          [id]="'btn-icon-secondary'"
          [icon]="'icon_review_info'"
          [text]="'Solicitar revisão da requisição'"
          altText="{{ 'Ícone Revisão' }}"
          (click)="openReviewRequestModal()"
        >
        </app-button-icon-secondary>
        <app-button-icon-primary
          *ngIf="this.consolidationScopeChange && !isReview"
          [id]="'btn-icon'"
          [icon]="scopeChange?.confirmDeadLine ? 'icon_edit_white' : 'icon_check_small'"
          [text]="scopeChange?.confirmDeadLine ? 'Editar parecer final' : 'Consolidar parecer final'"
          altText="{{ 'Ícone Check' }}"
          (click)="redirectToScopeChangePublishFinal()"
        >
        </app-button-icon-primary>
      </div>
    </header>

    <div class="outer__wrapper">
      <div nz-row [nzGutter]="[16, 16]" class="analisys-component-container">
        <div nz-col [nzSpan]="9">
          <div class="round-border">
            <app-analysis-info
              [scopeChangeId]="scopeChangeId"
              [scopeChange]="scopeChange"
              [project]="project"
            ></app-analysis-info>
          </div>
        </div>

        <div nz-col [nzSpan]="15" class="sl__simpleLayout__wrapper">
          <div class="app-analysis-about" >
            <app-analysis-about
              [scopeChangeId]="scopeChangeId"
              [scopeChange]="scopeChange"
              [analysisInformations]="analysisInfo"
              [project]="project"
              [getUserList]="getUserByRoleAndComplex"
              [isClosedOrCancelled]="isClosedOrCancelled"
              [edit]="this.anarequisition"
              (changeValue)="updateScopeChangeUser($event)"
            >
            </app-analysis-about>
          </div>

          <div *ngIf="scopeChange && opinionStatus !== 'HIDE'" >
            <app-final-opinion-me
              [scopeChangeId]="scopeChangeId"
              [editFinalOpnion]="consolidationScopeChange && !isReview"
              [opinionStatus]="opinionStatus"
              [scopeData]="scopeChange"
              [isClosedOrCancelled]="isClosedOrCancelled"
            >
            </app-final-opinion-me>
          </div>

          <app-requesting-area-confirmation
            *ngIf="scopeChange && scopeChange?.confirmDeadLine"
            [scopeChange]="scopeChange"
            [confirmScopeChange]="confirmScopeChange">
          </app-requesting-area-confirmation>


          <app-request-review
            *ngIf="scopeChange && isReview && project && project.id !== undefined"
            [scopeChangeName]="scopeChangeName"
            [scopeChange]="scopeChange"
            [scopeChangeId]="scopeChangeId"
            [projectId]="project.id"
            [showActionButtons]="true"
            [resolvReview]="this.cadScopeCHange"
            [editRequisition]="this.anarequisition"
            (isReloadData)="onReloadData($event)">
          </app-request-review>


          <div class="app-analysis-required">
            <app-analysis-required
              [anaRequisition]="this.anarequisition"
              [scopeChangeId]="scopeChangeId"
              [scopeChange]="scopeChange"
              [isClosedOrCancelled]="isClosedOrCancelled"
              [project]="project"
              (updateDisciplines)="updateDisciplines()"
              *ngIf="scopeChange !== null && project !== null">
            </app-analysis-required>
          </div>

          <!-- HISTORICOS DE PEDIDO DE ANALISE -->
          <div *ngIf="scopeChangeHistoryList > 0">
            <app-analysis-history [scopeChangeId]="scopeChangeId" [scopeChangeHistory]="scopeChangeHistory">
            </app-analysis-history>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
