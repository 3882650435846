import {Component, Input, OnInit, Output} from '@angular/core';
import {MessageService} from "../../../../../shared/common/services/message.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-discipline-assessment',
  templateUrl: './discipline-assessment.component.html',
  styleUrls: ['./discipline-assessment.component.scss']
})
export class DisciplineAssessmentComponent implements OnInit {
  @Input() id: number;
  @Input() text: string;
  public form!: UntypedFormGroup;
  

  constructor( private message: MessageService, private readonly fb: UntypedFormBuilder,) { }

  ngOnInit(): void {
    this.initForm();

    if(this.text){
      this.form.controls['analysisSummary'].setValue(this.text);
    }
  }

  initForm(){
    let analysisSummary = '';
    this.form = this.fb.group({
      analysisSummary: [analysisSummary, Validators.required],
    });
  }

  onChange($event){
    //plug emit
  }

}
