import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface configurationModalFilterCheckbox {
  isScroll: boolean;
}
@Component({
  selector: 'app-modal-filter-checkbox',
  templateUrl: './app-modal-filter-checkbox.component.html',
  styleUrl: './app-modal-filter-checkbox.component.scss'
})
export class ModalFilterCheckboxComponent{

  private _cleanValues: boolean = false;

  @Input() filterTableOptions: Array<{label: string, value: any, checked: boolean}> = [];
  @Input() configuration: configurationModalFilterCheckbox = {isScroll: false};
  @Output() changeSearchValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCleanValue: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      this.resetFilter();
    }
  };

  searchFilter(): void {
    let chekedValuesIds: number[] = this.filterTableOptions.filter((elem) => elem.checked).map((elem) => elem.value);
    this.changeSearchValue.emit(chekedValuesIds);
  }

  resetFilter(): void {
    this.filterTableOptions.map((elem) => elem.checked = false);
    this.changeCleanValue.emit(true);
  }

  hasFilterValue(): boolean {
    return this.filterTableOptions.some((elem) => elem.checked);
  }



}
