import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ScopeChangeService } from '../../service/scope-change/scope-change.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { UploadFilesComponent } from 'src/app/shared/components/upload-files/upload-files.component';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import { AboutProjectContainerComponent } from '../../components/scope-change-form/about-project-container/about-project-container.component';
import { ScopeChangeResponse } from '../../interface/scope-change-request';
import { SummaryScopeChange, SummaryAboutProject } from '../../interface/summary-scope-change';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { ProgressModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component';
import { ConfirmationSendAnalisysModalComponent } from 'src/app/modules/projects-v2/modal/confirmation-send-analisys-modal/confirmation-send-analisys-modal.component';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { UtilService } from 'src/app/shared/util.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

@Component({
  selector: 'app-scope-change-review',
  templateUrl: './scope-change-review.component.html',
  styleUrls: ['./scope-change-review.component.scss']
})
export class ScopeChangeReviewComponent implements OnInit {

  private readonly destroy$: Subject<any> = new Subject();
  @Input() scopeChangeName: string;
  @ViewChild(AboutProjectContainerComponent) aboutProjectContainer: AboutProjectContainerComponent;
  @ViewChild(UploadFilesComponent) uploadFiles: UploadFilesComponent;

  @Output() handleUploadFiles = new EventEmitter<NzUploadFile[]>();

  formData: FormGroup;
  scopeSummary: SummaryScopeChange;
  projectSummary: SummaryAboutProject;

  projectId: number;
  scopeChangeId: number;
  scopeChange: ScopeChangeResponse;
  project: ProjectByIdResponseDto;
  projectLocation: any;
  checkValidateForm: boolean = false;
  projectName;
  cadScopeChange: boolean = false;

  scopeChangeAttachments: NzUploadFile[];

  constructor(private formBuilder: FormBuilder,
    private readonly attachmentsService: AttachmentsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private scopeChangeService: ScopeChangeService,
    private viewContainerRef: ViewContainerRef,
    private messageService: MessageService,
    private store: Store<BreadcrumbState>,
    private location: Location,
    private utilService: UtilService,
    private projectV2Service: ProjectsV2Service) { }

  ngOnInit(): void {
    this.cadScopeChange = this.utilService.getAuthorization('edit', 'CADSCOPECHANGE');
    this.scopeChange = this.activatedRoute.snapshot?.data['scopeChangeReviewData'];
    this.projectId = Number(this.activatedRoute.parent?.snapshot?.paramMap.get('projectId'));
    this.scopeChangeId = Number(this.activatedRoute.snapshot?.paramMap.get('scopeId'));
    this.activatedRoute.queryParams.subscribe(params => {
      this.scopeChangeName = params['scopeChangeName'];
    });
    this.initFormData();
    this.getProject(this.projectId)
    this.getAttachments();
  }

  initFormData(): void {
    this.formData = this.formBuilder.group({
      id: [null],
      projectId: [null, Validators.required],
      areaId: [null, Validators.required],
      regionId: [null, Validators.required],
      complexId: [null, Validators.required],
      operationalUnitId: [null, Validators.required],
      userLegalRepId: [null, Validators.required],
      scopeDescription: [null, Validators.required],
      projectCode: [null],
      classificationType: [null, Validators.required],
      comments: [null, Validators.required],
      scopeCategory: [null, Validators.required],
      isDraft: [null, Validators.required]
    });
  }

  onScroll() {
    document.body.click();
  }

  handleDetailAboutRequest(event: FormGroup): void {
    let scopeCategoryForm = event.get('scopeCategory')?.value;
    let scopeDescriptionForm = event.get('descriptionDetail')?.value;

    if (scopeCategoryForm) {
      let scopeCategoryId: Array<number> = [];
      scopeCategoryForm.forEach(element => scopeCategoryId.push(element.description));
      this.formData.controls['scopeCategory'].setValue(scopeCategoryId);
    }

    this.formData.controls['scopeDescription'].setValue(scopeDescriptionForm);
    this.scopeSummary = { change: scopeCategoryForm, description: scopeDescriptionForm };
  }

  handleFormChanges($event) {
    this.checkValidateForm = false;

    this.formData.controls['projectId'].setValue($event.projectId);
    this.formData.controls['areaId'].setValue($event.areaId);
    this.formData.controls['regionId'].setValue($event.corridorId);
    this.formData.controls['complexId'].setValue($event.complexId);
    this.formData.controls['operationalUnitId'].setValue($event.operationalUnitId);
    this.formData.controls['userLegalRepId'].setValue($event.userLegalRepId);
    this.formData.controls['projectCode'].setValue($event.projectCode);
    this.formData.controls['classificationType'].setValue($event.classificationType);
    this.formData.controls['comments'].setValue($event.comments);

    this.projectSummary = {
      projectName: $event.projectName,
      projectCode: $event.projectCode,
      projectNumber: this.scopeChange.projectNumber,
      projectClassification: $event.classificationType,
      projectDescription: $event.comments,
      localization: $event.location,
      areaRequest: $event.areaDescription,
      responsible: $event.userLegalName
    };
  }

  cancel(): void {
    this.modalService.confirm({
      ...(this.messageService.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.location.back();
      }
    });
  }

  reSendAnalysis(): void {
    this.checkValidateForm = true;

    const request: any = this.buildCreateRequestPayload();

    this.callToCreateScopeChange(request);
  }

  private buildCreateRequestPayload() {
    this.formData.controls['projectId'].setValue(this.projectId);
    this.formData.controls['isDraft'].setValue(false);

    const request: any = this.formData.value;
    if (this.scopeChangeId) {
      request.id = this.scopeChangeId;
    }
    request.isResend = true;

    let review = this.scopeChange?.subProjectReviewList?.filter((item) => item.status === 'EAN');
    request.subProjectReviewId = review[0].id;
    return request;
  }

  private callToCreateScopeChange(request: any) {
    this.scopeChangeService.createScopeChange(request)
      .subscribe({
        next: (response) => {
          this.onUpload(response.id);
          this.modalAnaliseRealizada(this.projectId);
        },
        error: () => {
          this.messageService.showErrorMessage("Erro ao enviar para análise, verifique se todos os campos foram preenchidos.");
        },
      });
  }

  modalAnaliseRealizada(entityId: number) {
    const modal = this.modalService.create<ConfirmationSendAnalisysModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: ConfirmationSendAnalisysModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.projectId = entityId ? entityId : this.projectId;

    modal.afterClose.subscribe(() => {
      this.router.navigate([`/${RoutesEnum.PROJECTS}${entityId}`]);
    })
  }

  onUpload(idScopeChange: number): void {
    if (this.uploadFiles.fileList.length > 0) {
      let modalProgressComponent = this.modalProgress();
      this.uploadFiles.attachmentsId = idScopeChange;
      this.uploadFiles.uploadFilesAndUpdateProgress(idScopeChange, modalProgressComponent);
      this.uploadFiles.reload.subscribe((emit) => {});
    }
  }

  private modalProgress(): ProgressModalComponent {
    const modal = this.modalService.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.subtitulo = 'Isso pode levar alguns minutos';
    instance.titulo = 'Fazendo upload dos arquivos...';
    instance.progress = 0;

    return instance;
  }

  goBack(): void {
    this.location.back();
  }


  getProject(projectId: number) {
    this.projectV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project) => {
        this.project = project;
        this.projectName = project.name
      },
      complete: () => {
        this.dispatchProjectOnStore()
      }
    })
  }

  getAttachments() {
    this.attachmentsService.getAttachmentsByEntityIdEntityNameAndContextInfo(
      this.scopeChangeId,
      "SCOPE_CHANGE",
      ""
    ).subscribe({
      next: (attachments) => {
        this.scopeChangeAttachments = this.buildNzUploadFileArrayFromResponse(attachments);
      }
    })
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  private dispatchProjectOnStore(): void {

    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.project?.id
    });

     const routeProjectScopeChange = buildRoute(RoutesEnum.SCOPE_CHANGE, {
      projectId: this.project?.id
    });

    const routeScopeChange = buildRoute(RoutesEnum.SCOPE_CHANGE_DETAIL, {
      id: this.scopeChangeId
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: this.projectName,
        url: `/${routeProject}`
      },
      {
        name: 'Mudança de escopo',
        url: `/${routeProjectScopeChange}/${routeScopeChange}`
      },
      {
        name: 'Pedido de revisão',
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }
}
