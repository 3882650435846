<div class="app-modal-filter-checkbox">
    <i class="pi pi-filter ft__size12" [class.has__value]="hasFilterValue()" (click)="modalCheckboxFilter.toggle($event)"></i>

    <p-overlayPanel #modalCheckboxFilter>
        <div class="ant-table-filter-dropdown checkbox__container_overlay" [class.checkbox__container_overlay__scroll]="configuration.isScroll">
          <div class="search__box">
            <nz-checkbox-group [(ngModel)]="filterTableOptions" (ngModelChange)="searchFilter()">
              <label nz-checkbox *ngFor="let option of filterTableOptions">
                {{ option.label }}
              </label><br>
            </nz-checkbox-group>
      
            <nz-divider class="divider16"></nz-divider>
      
            <div class="buttons__box">
              <button class="clean__button" nz-button nzSize="small" (click)="resetFilter()">
                Limpar filtro
              </button>
            </div>
          </div>
        </div>
    </p-overlayPanel>
</div>