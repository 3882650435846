import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// PrimeNG
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';

// Shared Components
import { ButtonsLicensingModule } from 'src/app/shared/components/buttons/buttons-licensing.module';

// Directives
import { TooltipDirective } from './directives/tooltip.directive';

// View Components
import { ChatComponent } from './view/chat/chat.component';
import { ActionBarComponent } from './components/chat-view/action-bar/action-bar.component';
import { SelectFilesListComponent } from './components/chat-view/select-files-list/select-files-list.component';
import { CardTitleComponent } from './components/chat-view/card-title/card-title.component';
import { StartChatAIComponent } from './components/chat-view/start-chat-ai/start-chat-ai.component';
import { ConversationChatAIComponent } from './components/chat-view/conversation-chat-ia/conversation-chat-ia.component';
import { FileSelectionComponent } from './components/file-selection/file-selection.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { DropdownModule } from 'primeng/dropdown';
import { TreeSelectModule } from 'primeng/treeselect';
import { SharedModule } from 'src/app/shared/shared.module';
import { feedbackComponent } from './components/chat-view/feedback/feedback.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../scope-change/scope-change.module';
import { HttpClient } from '@angular/common/http';
import { ModalConfirmSendEmailComponent } from './components/chat-view/modal-confirm-send-email/modal-confirm-send-email.component';
import { ChatIaRoutingModule } from './chat-ia-routing.module';
import { MainComponent } from './view/main/main.component';
import { HistoricChatsComponent } from './view/historic-chats/historic-chats.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { TableLicensingModule } from 'src/app/shared/components/tables/table-licensing.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

const COMPONENTS = [
  ChatComponent,
  ActionBarComponent,
  CardTitleComponent,
  SelectFilesListComponent,
  StartChatAIComponent,
  ConversationChatAIComponent,
  FileSelectionComponent,
  TooltipDirective,
  feedbackComponent,
  ModalConfirmSendEmailComponent,
  HistoricChatsComponent,
  MainComponent
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ChatIaRoutingModule,
  ButtonsLicensingModule,
  InputTextModule,
  FloatLabelModule,
  NzFormModule,
  CheckboxModule,
  TreeSelectModule,
  DropdownModule,
  OverlayPanelModule,
  NzSpinModule,
  SharedModule,
  TableLicensingModule,
  NzToolTipModule
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    ...MODULES,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class ChatIAModule { }
