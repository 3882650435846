<div class="round-border mb-16" *ngIf="subProjectReview">
<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24"
class="app-resume-scope-change-request-container"
>

    <div class="scope">
        <h2 class="scope__title">
            <!-- Resumo da requisição -->
            {{"projects.scope-change.request-review.title" | translate }}
        </h2>

        <div class="scope__items">
            <div class="scope__items__item"
            id="resumoEscopo"
            >

                    <div class="scope__items__item__row">
                        <p class="scope__items__item__row__title">
                            <!-- Mudanças -->
                            {{"projects.scope-change.request-review.request-date" | translate }}
                        </p>
                        <ul>
                            <li class="scope__items__item__row__description">
                                {{ subProjectReview.registrationDate | date: 'dd/MM/yyyy' }}
                            </li>
                        </ul>
                    </div>

                <div class="scope__items__item__row">
                    <p class="scope__items__item__row__title">
                        <!-- Observações -->
                        {{"projects.scope-change.request-review.deadline-date" | translate }}

                    </p>
                    <p class="scope__items__item__row__description" [class.scope__items__item__row__infoColor]="badgeConfig.badgeText !== ''">
                        {{ subProjectReview.deadline| date: 'dd/MM/yyyy' }}
                        <img src="assets/icons/information-icon.svg"
                        alt="info"
                        nz-tooltip
                        nzTooltipPlacement="bottomRight"
                        nzTooltipTitle="Requisições sem resposta em até 120 dias serão canceladas e arquivadas"
                        [nzTooltipArrowPointAtCenter]="false">
                    </p>
                </div>

                    <div class="scope__items__item__row">
                        <p class="scope__items__item__row__title">
                            <!-- Observações -->
                            {{"projects.scope-change.request-review.requested-by" | translate }}
                        </p>
                        <p class="scope__items__item__row__description">
                            {{ subProjectReview.user.name }}
                        </p>
                    </div>

                    <div class="scope__items__item__row">
                        <p class="scope__items__item__row__title">
                            <!-- Observações -->
                            {{"projects.scope-change.request-review.description" | translate }}
                        </p>
                        <p class="scope__items__item__row__description">
                            {{ subProjectReview.comments }}
                        </p>
                    </div>
                </div>

                <app-badge-chips
                    *ngIf="isDemandant() && badgeConfig.badgeText"
                    [badgeColor]="badgeConfig.badgeColor"
                    [badgeText]="badgeConfig.badgeText"
                    [badgeIcon]="badgeConfig.badgeIcon">
                </app-badge-chips>

        </div>
        <div class="action-buttons" *ngIf="showActionButtons && badgeConfig.badgeColor !== 'info'">
            <app-button-icon-secondary
                *ngIf="editRequisition"
                [id]="'btn-icon-secondary'"
                [icon]="'icon_edit'"
                [text]="'Editar'"
                altText="{{ 'Editar solicitação de revisão' }}"
                customClass="icon__size_18"
                (click)="onEditReview(subProjectReview)"
                class="ml-20">
            </app-button-icon-secondary>

            <app-button-icon-secondary
                *ngIf="editRequisition"
                [id]="'btn-icon-secondary'"
                [icon]="'cross-circle'"
                [text]="'Cancelar pedido de revisão'"
                altText="{{ 'Cancelar pedido de revisão' }}"
                (click)="onCancelReview()"
                customClass="icon__size_21"
                class="ml-20">
            </app-button-icon-secondary>

            <app-button-icon-secondary
                *ngIf="resolvReview"
                [id]="'btn-icon-secondary'"
                [icon]="'clipboard-edit-left-2'"
                [text]="'Responder pedido de revisão'"
                altText="{{ 'Responder pedido de revisão' }}"
                class="ml-20"
                customClass="icon__size_18"
                (click)="goToReview()"
                (keypress)="goToReview()">
            </app-button-icon-secondary>
        </div>
    </div>
</div>
</div>
