import { Component, EventEmitter, Input,  Output } from '@angular/core';
@Component({
  selector: 'app-feedback',
  standalone: false,
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class feedbackComponent {
  @Output() feedbackAction: EventEmitter<string> = new EventEmitter<string>();
  @Input() isPositive: boolean | undefined;

  callAction(action: string): void {
    this.feedbackAction.emit(action);
  }

}
