import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Location } from "@angular/common";
import { UntypedFormGroup } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { DisciplineAssessmentComponent } from '../../components/scope-change-discipline-assessment/discipline-assessment/discipline-assessment.component';
import { MessageService } from '../../../../shared/common/services/message.service';
import { UtilService } from '../../../../shared/util.service';
import { AssessmentUpdateDto } from 'src/app/shared/dto/assessment-update.dto';
import { DisciplineAssessmentService } from 'src/app/shared/services/discipline-assessment.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SuccessConfirmationComponent } from 'src/app/modules/projects-v2/project-v2-assessment/success-confirmation/success-confirmation.component';
import { ErrorModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/error-modal/error-modal.component';
import { SummaryAboutProject, SummaryScopeChange } from '../../interface/summary-scope-change';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
// import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { AnalysisInformations } from 'src/app/shared/dto/analysis-info.dto';
import { UserService } from 'src/app/shared/services/user.service';
import { UploadFilesComponent } from 'src/app/shared/components/upload-files/upload-files.component';
import { ProgressModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

@Component({
  selector: 'app-scope-change-discipline-assessment',
  templateUrl: './scope-change-discipline-assessment.component.html',
  styleUrls: ['./scope-change-discipline-assessment.component.scss']
})
export class ScopeChangeDisciplineAssessmentComponent implements OnInit, OnDestroy {
  @ViewChild(DisciplineAssessmentComponent) formSummary: DisciplineAssessmentComponent;
  @ViewChild(UploadFilesComponent) uploadFiles: UploadFilesComponent;
  private readonly destroy$: Subject<any> = new Subject();

  attachments: NzUploadFile[];
  assessmentName: string = "Análise técnica de "
  blockScopeChange: boolean = false;
  projectSummary: SummaryAboutProject;
  scopeSummary: SummaryScopeChange;
  baseData: any;
  loggedUser: any;
  form!: UntypedFormGroup;
  disciplineAssessment;
  subProjectId;
  assesmentText:string;
  analysisInfo: AnalysisInformations;
  status:string;
  entityId:number;
  disciplineAssessmentScopeChange: boolean = false;

  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];

  constructor(private route: ActivatedRoute,
              private message: MessageService,
              private utilService: UtilService,
              private assessmentService: DisciplineAssessmentService,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private router: Router,
              private attachmentsService: AttachmentsService,
              private location: Location,
              private userService: UserService,
              private store: Store<BreadcrumbState>) { }

  ngOnInit(): void {
    this.disciplineAssessmentScopeChange = this.utilService.getAuthorization('insert', 'DISCIPLINEASSESMENTSCOPECHANGE')
    this.baseData = this.route.snapshot.data['scopeChangeDiscipline'];
    this.status = this.baseData['disciplineAssessment']?.data?.status;
    this.assessmentName += this.baseData['disciplineAssessment']?.data?.discipline?.description;
    const userRecuperado = sessionStorage.getItem("loggedUser")
    this.loggedUser =  userRecuperado ?  JSON.parse(userRecuperado): null;
    this.entityId = this.baseData.disciplineAssessment.data.id;
    this.getProjectData();
    this.getScopeChangeData();
    this.dispatchProjectOnStore()

    this.assesmentText = this.baseData.disciplineAssessment.data.analysisSummary;
  }

  getProjectData(){
    this.projectSummary = {
      projectName: this.baseData['projectData'].name,
      projectCode: this.baseData['projectData'].code,
      projectNumber: this.baseData['projectData'].number,
      projectClassification: this.baseData['projectData'].classificationType,
      projectDescription: this.baseData['projectData'].comments,
      localization: this.baseData['projectData'].region.description + ' / ' + this.baseData['projectData'].complex.description + (this.baseData['projectData'].projectOperationalUnit ? ' / ' + this.baseData['projectData'].operationalUnit : '' ),
      areaRequest: this.baseData['projectData'].area.description,
      responsible: this.baseData['projectData'].userLegalRepresentative.name
    };
  }

  getScopeChangeData(){
    this.scopeSummary = {
      change: this.baseData['scopeChangeData'].scopeChangeReasons,
      description: this.baseData['scopeChangeData'].commentsAboutChange,
    }
    this.blockScopeChange = this.baseData['scopeChangeData'].status === 'CAN' || this.baseData['scopeChangeData'].status === 'CON';
    this.getAnalysisInfo();

    const scopeChangeId = this.route.snapshot?.params['scopeId'];
    if (scopeChangeId) {
      this.getAttachments(this.entityId, 'DISC_ASSESSMENT');
    }
  }

  getAnalysisInfo() {
    this.analysisInfo = {
      number: this.baseData['scopeChangeData']?.number || "",
      responsible: this.baseData['scopeChangeData']?.userLegalRepresentative || null,
      registrationDate: this.baseData['scopeChangeData']?.registrationDate || "",
      deadline: this.baseData['scopeChangeData']?.analysisDeadLine || "",
      status: this.baseData['scopeChangeData']?.status || "INI"
    }
  }

  getAttachments(id: number, name: string) {
    this.attachmentsService.getAttachmentsByEntityAndEntityId(
      id, name
    ).subscribe({
      next: (attach) => {
        this.attachments = this.utilService.buildNzUploadFileArrayFromResponse(attach);
        this.uploadFiles.recoverFilesAlreadyUploaded(this.attachments);
      }
    })
  }

  submitForm(status:string){
    const  analysisSummaryLength = this.formSummary.form.controls['analysisSummary'].value.length;

      if (this.formSummary.form.controls['analysisSummary'].value && analysisSummaryLength <= 2000) {
        let payload: AssessmentUpdateDto  = this.buildPayload(status);
         this.assessmentService.updateAssessment(payload).subscribe({
          next: (data) => {
            this.status = status;
            this.uploadAfterAction(this.entityId);
          },
          error: (error) => {
            this.modalError(error.error.message);
          }
        });

      } else {
        this.showInvalidFormMensages(analysisSummaryLength);
      }
    }

  private showInvalidFormMensages(length: number) {
    const messageError = length <= 2000 ? "Por gentileza, preencher o resumo da análise" :
     "Limite de 2000 caracteres excedido, por gentileza, corrigir o excesso";
    this.message.showErrorMessage(messageError);
    Object.values(this.formSummary.form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  private buildPayload(status: string): AssessmentUpdateDto {
    let analysisDeadlineAfter = this.utilService.DateFormatter(this.form?.value?.analysisDeadlineAfter);
    if (this.formSummary.form?.value?.requiresFieldVisit == 'false') {
      analysisDeadlineAfter = '';
    }

    return {
      id: this.baseData.disciplineAssessment.data.id,
      idDiscipline: this.baseData.disciplineAssessment.data.discipline.id,
      analysisSummary: this.formSummary.form.value.analysisSummary,
      idUserAssessment: this.loggedUser.id,
      requiresFieldVisit: false, //not on ME form as in ripla
      //analysisDeadlineAfter: analysisDeadlineAfter,
      status: status
    }
  }

  uploadAfterAction(id){
    if (this.uploadFiles.fileList.length > 0) {
      let modalProgressComponent = this.modalProgress();
      this.uploadFiles.uploadFilesAndUpdateProgress({id}, modalProgressComponent);
    } else {
      this.modalSuccess(this.status);
    }
  }

  finishUpload($event){
    this.modalSuccess(this.status);
  }



  private modalProgress(): ProgressModalComponent {
    const modal = this.modal.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.subtitulo = 'Isso pode levar alguns minutos';
    instance.titulo = 'Fazendo upload dos arquivos...';
    instance.progress = 0;

    return instance;
  }

  handleUploadChanges($event: any[]) {
    this.attachments = $event;
    $event.forEach((elem, index) => {
      if (elem.status === 'error') {
        this.attachments[index].status = 'error';
      }
    })
  }

  modalSuccess(status:string) {
    const modal = this.modal.create<SuccessConfirmationComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: SuccessConfirmationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    if (status == 'CON') {
      instance.title = 'Sua análise foi finalizada com sucesso!'
      instance.subTitle = 'A análise foi enviada para o time de licenciamento que poderá entrar em contato para eventuais revisões e validações necessárias.'
    }
    if (status == 'EAN') {
      instance.title = 'Rascunho salvo com sucesso!';
      instance.subTitle = 'Você poderá retornar a esta avaliação e concluí-la depois.';
    }

    modal.afterClose.subscribe(() => {
      this.route.parent?.params.subscribe(params => {
        const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
          projectId: params['projectId']
        });

        const routeDetail = buildRoute(RoutesEnum.SCOPE_CHANGE_DETAIL, {
          id: this.baseData.scopeChangeData.id
        });

        this.router.navigate([`${routeProject}/requisicoes/${routeDetail}`]);
      });
    });
  }

  modalError(errorMessage: string): void {
    const modal = this.modal.create<ErrorModalComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ErrorModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.errorMessage = 'Não foi possível finalizar a avaliação';
    instance.subTitle = errorMessage;
  }

  navigateBack(){
    this.location.back();
  }


  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(): void {
    // let project: ProjectState = {
    //   name: 'Projetos',
    //   url: 'projects-v2/',
    //   last: false,
    //   sublevels: true,
    //   project: {
    //     name: this.baseData['projectData'].name,
    //     url: 'project-v2-view/' + this.baseData['projectData'].id,
    //     last: false,
    //   },
    //   subProject: {
    //     name: 'Mudança de escopo',
    //     url: "scope-change/analysis/" + this.route.snapshot.url[1].path,
    //     last: true,
    //   },
    //   mudancaEscopo:{
    //     name: 'Análise de ' + this.baseData['disciplineAssessment']?.data?.discipline?.description,
    //     url: "",
    //     last: true,
    //   },
    //   parecerFinal: null,
    //   pedidoRevisao: null,
    //   comunicacoes: null,
    //   processos: null,
    //   atividades: null,
    //   subatividades: null
    // }

    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.baseData['projectData'].id
    });

    const routeScopeChangeProject = buildRoute(RoutesEnum.SCOPE_CHANGE, {
      projectId: this.baseData['projectData'].id
    });

    const routeScopeChange = buildRoute(RoutesEnum.SCOPE_CHANGE_DETAIL, {
      id: this.route.snapshot.url[1].path
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: this.baseData['projectData'].name,
        url: `/${routeProject}`
      },
      {
        name: "Mudança de escopo",
        url: `/${routeScopeChangeProject}/${routeScopeChange}`
      },
      {
        name: "Análise da disciplina",
        url: ""
      }
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

}
