import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AboutProjectContainerComponent } from '../../components/scope-change-form/about-project-container/about-project-container.component';
import { SummaryAboutProject, SummaryScopeChange } from '../../interface/summary-scope-change';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import { ScopeChangeService } from '../../service/scope-change/scope-change.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ScopeChangeResponse } from '../../interface/scope-change-request';
import { Subject } from 'rxjs';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { UploadFilesComponent } from 'src/app/shared/components/upload-files/upload-files.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProgressModalComponent } from 'src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { SCOPE_CHANGE_ENTITY_NAME } from 'src/app/modules/projects/constants/projectsNewConstants';
import { ConfirmationSendAnalisysModalComponent } from 'src/app/modules/projects-v2/modal/confirmation-send-analisys-modal/confirmation-send-analisys-modal.component';
import { ConfirmationSendDraftModalComponent } from 'src/app/modules/projects-v2/modal/confirmation-send-draft-modal/confirmation-send-draft-modal.component';
import { UtilService } from 'src/app/shared/util.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

@Component({
  selector: 'app-scope-change-request',
  templateUrl: './scope-change-request.component.html',
  styleUrls: ['./scope-change-request.component.scss']
})
export class ScopeChangeRequestComponent implements OnInit, OnDestroy, AfterViewInit {
  //readonly #modal = inject(NzModalRef);

  @ViewChild(AboutProjectContainerComponent) aboutProjectContainer: AboutProjectContainerComponent;
  private readonly destroy$: Subject<any> = new Subject();

  @ViewChild(UploadFilesComponent) uploadFiles: UploadFilesComponent;

  @Output() handleUploadFiles = new EventEmitter<NzUploadFile[]>();

  formData: FormGroup;
  scopeSummary: SummaryScopeChange;
  projectSummary: SummaryAboutProject;
  attachmentSummary: NzUploadFile[] = [];

  projectId: number;
  scopeChangeId: number;
  scopeChange: ScopeChangeResponse;
  project: ProjectByIdResponseDto;
  projectLocation: any;
  checkValidateForm: boolean = false;
  cadScopeChange: boolean = false;



  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private scopeChangeService: ScopeChangeService,
    private viewContainerRef: ViewContainerRef,
    private messageService: MessageService,
    private projectV2Service: ProjectsV2Service,
    private store: Store<BreadcrumbState>,
    private attachmentsService: AttachmentsService,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.cadScopeChange = this.utilService.getAuthorization('insert', 'CADSCOPECHANGE');
    this.projectId = this.activatedRoute.snapshot?.data['scopeChangeData'].id;
    this.project = this.activatedRoute.snapshot?.data['scopeChangeData'];
    this.scopeChangeId = Number(this.activatedRoute.snapshot?.paramMap.get('scopeId'));
    this.initFormData();
    this.loadProjectForStore()

    if (this.scopeChangeId) {
      this.getScopeChange();
    }
  }


  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.getAttachment();
  }

  ngAfterViewInit(): void {
    this.uploadFiles?.filesEmit?.subscribe((emit) => this.handleUploadFiles.emit(emit));
  }

  private loadProjectForStore(): void {
    this.dispatchProjectOnStore()
  }

  private dispatchProjectOnStore(): void {

    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: this.project.id
    });

    const pagesArray: Page[] = [
      {
        name: "Projetos",
        url: `/${RoutesEnum.PROJECTS}`
      },
      {
        name: this.project.name,
        url: `${routeProject}`
      },
      {
        name: 'Pedido de mudança de escopo',
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  // FINAL BREADCRUMB


  initFormData(): void {
    this.formData = this.formBuilder.group({
      id: [null],
      projectId: [null, Validators.required],
      areaId: [null, Validators.required],
      regionId: [null, Validators.required],
      complexId: [null, Validators.required],
      operationalUnitId: [null, Validators.required],
      userLegalRepId: [null, Validators.required],
      scopeDescription: [null, Validators.required],
      projectCode: [null],
      classificationType: [null, Validators.required],
      comments: [null, Validators.required],
      scopeCategory: [null, Validators.required],
      isDraft: [null, Validators.required]
    });
  }

  sendAnalysis(){
    this.checkValidateForm = true;
    this.formData.controls['projectId'].setValue(this.projectId);
    this.formData.controls['isDraft'].setValue(false);
    this.aboutProjectContainer.sendDraft = false;
    const request: any = this.formData.value
    if (this.scopeChangeId) {
      request.id = this.scopeChangeId
    }

    this.scopeChangeService.createScopeChange(request)
      .subscribe({
        next: (response) => {
          this.onUpload(response.id);
          this.modalAnaliseRealizada(this.projectId);
        },
        error: (err) => {
          this.messageService.showErrorMessage("Erro ao enviar para análise, verifique se todos os campos foram preenchidos.")
        },
      });
  }

  saveDraft() {
    this.checkValidateForm = false;
    this.formData.controls['projectId'].setValue(this.projectId);
    this.formData.controls['isDraft'].setValue(true);
    this.aboutProjectContainer.sendDraft = true;

    const request: any = this.formData.value
    if (this.scopeChangeId) {
      request.id = this.scopeChangeId
    }

    this.scopeChangeService.createScopeChange(request)
      .subscribe({
        next: (response) => {
          this.onUpload(response.id);
          this.modalDraftRealizada();
        },
        error: (err) => {
          this.messageService.showErrorMessage("Erro ao salvar rascunho")
        },
      });
  }

  onUpload(idScopeChange: number): void {
    if (this.uploadFiles.fileList.length > 0) {
      let modalProgressComponent = this.modalProgress();
      this.uploadFiles.attachmentsId = idScopeChange;
      this.uploadFiles.uploadFilesAndUpdateProgress(idScopeChange, modalProgressComponent);
      this.uploadFiles.reload.subscribe((emit) => {});
    }
  }

  handleUploadChanges($event: any[]) {
    this.attachmentSummary = $event;
    $event.forEach((elem, index) => {
      if (elem.status === 'error') {
        this.attachmentSummary[index].status = 'error';
      }
    })
  }

  private modalProgress(): ProgressModalComponent {
    const modal = this.modalService.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.subtitulo = 'Isso pode levar alguns minutos';
    instance.titulo = 'Fazendo upload dos arquivos...';
    instance.progress = 0;

    return instance;
  }

  handleFormChanges($event){
    this.checkValidateForm = false;

    this.formData.controls['projectId'].setValue($event.projectId);
    this.formData.controls['areaId'].setValue($event.areaId);
    this.formData.controls['regionId'].setValue($event.corridorId);
    this.formData.controls['complexId'].setValue($event.complexId);
    this.formData.controls['operationalUnitId'].setValue($event.operationalUnitId);
    this.formData.controls['userLegalRepId'].setValue($event.userLegalRepId);
    this.formData.controls['projectCode'].setValue($event.projectCode);
    this.formData.controls['classificationType'].setValue($event.classificationType);
    this.formData.controls['comments'].setValue($event.comments);

    this.projectSummary = {
      projectName: $event.projectName,
      projectCode: $event.projectCode,
      projectNumber: $event.projectNumber,
      projectClassification: $event.classificationType,
      projectDescription: $event.comments,
      localization: $event.location,
      areaRequest: $event.areaDescription,
      responsible: $event.userLegalName
    };
  }

  setProjectSummary() {
    if (!this.scopeChangeId) {
      const projectForSummary = this.project as any;
      this.projectSummary = {
        projectName: projectForSummary.name,
        projectCode: projectForSummary.code,
        projectNumber: projectForSummary.number,
        projectClassification: projectForSummary.classificationType,
        projectDescription: projectForSummary.comments,
        localization: this.getLocalization(projectForSummary),
        areaRequest: projectForSummary?.area?.description,
        responsible: projectForSummary?.userLegalRepresentative?.name
      };
    }
  }

  private getLocalization(project: any | null): string {
    let localization = "";

    if (project && project.complex) {
      localization = `${project.region?.description} / ${project.complex.description}`
      if (project.operationalUnit) {
        localization += ` / ${project.operationalUnit.description}`
      }
    }

    return localization;
  }

  handleDetailAboutRequest(event: FormGroup): void {
    let scopeCategoryForm = event.get('scopeCategory')?.value;
    let scopeDescriptionForm = event.get('descriptionDetail')?.value;

    if (scopeCategoryForm) {
      let scopeCategoryId: Array<number> = [];
      scopeCategoryForm.forEach(element => scopeCategoryId.push(element.description));
      this.formData.controls['scopeCategory'].setValue(scopeCategoryId);
    }

    this.formData.controls['scopeDescription'].setValue(scopeDescriptionForm);
    this.scopeSummary = { change: scopeCategoryForm, description: scopeDescriptionForm};
  }

  onCancel(): void {
    this.modalService.confirm({
      ...(this.messageService.showMessageModalConfirmCancelScopeChange() as any),
      nzOnOk: () => {
        this.router.navigate([this.getRouteProject(this.projectId)]);
      }
    });
  }

  onScroll() {
    document.body.click();
  }

  goBack(){
    this.router.navigate([this.getRouteProject(this.projectId)]);
  }

  getAttachment() {
    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.scopeChangeId !== null ? +this.scopeChangeId : 0,
        SCOPE_CHANGE_ENTITY_NAME)
      .subscribe((data) => {
        this.attachmentSummary = this.buildNzUploadFileArrayFromResponse(data);
      });
  }

  getScopeChange(): void{
    this.scopeChangeService.getScopeChange(this.scopeChangeId)
    .subscribe((response) => {
      this.scopeChange = response;
    })
  }

  modalAnaliseRealizada(entityId: number) {
    const modal = this.modalService.create<ConfirmationSendAnalisysModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: ConfirmationSendAnalisysModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.projectId = entityId ? entityId : this.projectId;

    modal.afterClose.subscribe(() => {
      this.router.navigate([this.getRouteProject(entityId)]);
    })
  }

  modalDraftRealizada() {
    const modal = this.modalService.create<ConfirmationSendDraftModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzKeyboard: false,
      nzContent: ConfirmationSendDraftModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    modal.afterClose.subscribe(() => {
      this.router.navigate([this.getRouteProject(this.projectId)]);
    })
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  getRouteProject(projectId: string | number): string {
    return buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: `${projectId}`
    });
  }



}
