<section class="analisys-info-container sl__simpleLayout">
  <header class="sl__simpleLayout__card__header">
    <h2 class="sl__simpleLayout__card__header__title">
      {{ "projects.scope-change.requisition-summary" | translate }}
    </h2>
  </header>
  <app-resume-scope-change-request-container
    *ngIf="summaryScopeChange && projectSummary"
    [shouldShowTitle]="false"
    [scopeSummary]="summaryScopeChange"
    [projectSummary]="projectSummary"
    [attachments]="scopeChangeAttachments">
  </app-resume-scope-change-request-container>
</section>
