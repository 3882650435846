<div>
    <div class="content mb-10">
        <h3 class="h3-title">Análise Técnica</h3>
        <div nz-row>
            <div class="fw">
                <app-input-textarea [id]="'input-text-area-detail'"
                    [name]="'projects.assessment.analysisSummaryScope' | translate"
                    [placeholder]="'projects.assessment.provideSummary' | translate" errorMessage="" [status]="' '"
                    [styleResize]="'none'" [required]="true" [controls]="form.get('analysisSummary')"
                    (changeValue)="onChange($event)" [charCount]="2000">
                </app-input-textarea>
            </div>
        </div>
    </div>
</div>