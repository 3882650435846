<div class="app-feedback">
  <div class="actions">
    <a class="icon copy"  (click)="callAction('copy')"
      [appTooltip]="'chat-ia.feedback.copy' | translate"
      tooltipArrowPosition="bottom"
      tooltipArrowAlignment="left"
      tooltipWidth="130px"
      [tooltipContainerPositionIsBottom]="true"></a>

    <a class="icon like" (click)="callAction('like')"
      [class.disabled]="isPositive !== null"
      [class.clicked]="isPositive"
      [appTooltip]="'chat-ia.feedback.like' | translate"
      tooltipArrowPosition="bottom"
      tooltipArrowAlignment="left"
      tooltipWidth="110px"
      [tooltipContainerPositionIsBottom]="true"></a>

    <a class="icon deslike" (click)="callAction('deslike')"
      [class.disabled]="isPositive !== null"
      [class.clicked]="isPositive !== null && !isPositive"
      [appTooltip]="'chat-ia.feedback.deslike' | translate"
      tooltipArrowPosition="bottom"
      tooltipArrowAlignment="left"
      tooltipWidth="210px"
      [tooltipContainerPositionIsBottom]="true"></a>
  </div>
</div>
