<nz-layout class="layout">
  <nz-sider
    nzBreakpoint="md"
    nzWidth="260px"
    nzCollapsed="false"
    [nzCollapsedWidth]="72"
    nzCollapsible
    (nzCollapsedChange)="forceHideText()"
    [ngClass]="{ 'collapsed': !menuFull }"
    class="sl-sider">
      <app-sidebar [expanded]="menuFull"></app-sidebar>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <app-topbar></app-topbar>
    </nz-header>
    <nz-content class="inner-content">
      <app-alert-message></app-alert-message>
      <div>
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <app-chat></app-chat>
  </nz-layout>
</nz-layout>
