<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <section class="app-scope-change-discipline-assesment sl__simpleLayout">
        <header>
            <div class="sl__simpleLayout__header__content">
                <button type="button" id="button-back" class="sl__simpleLayout__header__buttonBack"
                    (click)="navigateBack()">
                    <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
                </button>
                <h1><app-short-text-hint [originalText]="assessmentName" [maxChars]="60"></app-short-text-hint>
                </h1>
            </div>

            <div *ngIf="disciplineAssessmentScopeChange" class="sl__simpleLayout__header__nav">
                <app-button-icon-secondary *ngIf="this.status !== 'CON' && !blockScopeChange" [id]="'btn-icon-secondary'" [icon]="'icon_review_info'"
                    [text]="'Salvar Rascunho'" (click)="submitForm('EAN')"
                    alt="{{ 'Ícone Revisão' }}"></app-button-icon-secondary>
                <app-button-icon-primary [id]="'btn-icon'" [icon]="'icon_check_small'" (click)="submitForm('CON')"
                    [text]="'Finalizar Avaliação'" alt="{{ 'Ícone Check' }}"></app-button-icon-primary>
            </div>
        </header>

        <div class="outer__wrapper">
            <div nz-row [nzGutter]="[16, 16]">
                <div nz-col [nzSpan]="9">
                    <div class="round-border height-80">
                        <app-resume-scope-change-request-container [scopeSummary]="scopeSummary"
                            [projectSummary]="projectSummary"
                            [attachments]="attachments">
                        </app-resume-scope-change-request-container>
                    </div>
                </div>
                <div nz-col [nzSpan]="15">
                    <div class="mb-10">
                        <app-analysis-about [edit]="false" [scopeChangeId]="baseData['scopeChangeData'].id"
                            [analysisInformations]="analysisInfo" [project]="null"></app-analysis-about>
                    </div>
                    <div class="round-border mt-10">
                        <app-discipline-assessment #formSummary [text]="assesmentText"
                            [id]="baseData.disciplineAssessment.data.id"></app-discipline-assessment>

                            <div class="mt-20">
                                <app-upload-files
                                    #uploadFiles
                                    [attachmentsId]="entityId"
                                    entityName="DISC_ASSESSMENT"
                                    [disabledBlock]="false"
                                    (filesEmit)="handleUploadChanges($event)"
                                    (reload)="finishUpload($event)"
                                    displayType="COLUMN"
                                ></app-upload-files>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
