<section class="app-analysis-history sl__simpleLayout">
  <div class="sl__simpleLayout__card">
    <h2 class="sl__simpleLayout__card__title">
      <!-- Sobre a requisição -->
      {{'projects.scope-change.history' | translate}}
    </h2>

    <div class="historyAnalisys">
      <app-history-request-review-container *ngIf="scopeChange" [shouldShowTitle]="false" 
        [scopeChangesHistory]="scopeChange">
      </app-history-request-review-container>
    </div>

  </div>
</section>


