import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DisciplineBySubProjectListResponse} from "../../../../../shared/dto/discipline-by-subproject-list-response.dto";
import {UserModel} from "../../../../../shared/models/user.model";
import moment from "moment/moment";
import {differenceInCalendarDays} from "date-fns";
import {UserService} from "../../../../../shared/services/user.service";
import {ProjectsV2Service} from "../../../../../shared/services/projects-v2.service";
import {DisciplineAssessmentService} from "../../../../../shared/services/discipline-assessment.service";
import {MessageService} from "../../../../../shared/common/services/message.service";
import {DisciplinesService} from "../../../../../shared/services/disciplines.service";
import {UtilService} from "../../../../../shared/util.service";
import {ScopeChangeResponse} from "../../../interface/scope-change-request";
import {DisciplineDto} from "../../../../../shared/dto/discipline.dto";
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectByIdResponseDto } from 'src/app/shared/dto/project-by-id-response.dto';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-analysis-required',
  templateUrl: './analysis-required.component.html',
  styleUrls: ['./analysis-required.component.scss']
})
export class AnalysisRequiredComponent implements OnInit, OnChanges {
  disciplineListByProject: DisciplineBySubProjectListResponse[] = [];
  usersSpecialist: UserModel[] = [];
  users: UserModel[] = [];

  disciplinesList: DisciplineDto[] = [];
  projectId: number;
  subProjectId: number;
  editingRow = null;
  selectedUserResponsibleIdDiscipline: number = 0;
  selectedDisciplineId: number = 0;
  today = new Date();
  editDateDeadlineDiscipline: string = '';
  createDisciplineStudy: boolean = false;
  isEditing: boolean;
  editIdDiscipline: string | null = null;
  disciplineInEditMode: any;
  loadingSubProjects: boolean;

  newDateDeadlineDiscipline?: string = '';
  newIdDiscipline?: string = '';
  newStatusDiscipline: string = '';
  newUserResponsibleIdDiscipline?: string = '';
  addNewDiscipline: boolean = false;
  blockScopeChange: boolean = false;

  @Input() scopeChange!: ScopeChangeResponse;
  @Input() scopeChangeId!: number;
  @Input() project!: ProjectByIdResponseDto;
  @Input() anaRequisition: boolean = false;
  @Input() isClosedOrCancelled: boolean = true;
  @Output() updateDisciplines = new EventEmitter<boolean>();

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);

  constructor(
    private userService: UserService,
    private projectsV2Service: ProjectsV2Service,
    private disciplineAssessmentService: DisciplineAssessmentService,
    private readonly message: MessageService,
    private disciplinesService: DisciplinesService,
    private readonly utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
      this.createDisciplineStudy = this.utilService.getAuthorization('insert', 'CREATEDISCIPLINESTUDY');
      this.blockScopeChange = this.scopeChange.status === 'CAN';
      this.getDisciplines();
      this.orderAssessmentsByRegistration();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['scopeChange']?.currentValue
      ? this.orderAssessmentsByRegistration()
      : '';
  }

  startEditDiscipline(id, discipline): void {
    this.getUsersByDisciplineIdAndComplexId(discipline.discipline.id);
    if (discipline !== null) {
      this.editDateDeadlineDiscipline = discipline.analysisDeadline + 'T00:00:00';
      this.disciplinesList.push(discipline.discipline);
      this.selectedDisciplineId = discipline.discipline.id;
      this.selectedUserResponsibleIdDiscipline = discipline.userAssessment.id;
    }
    this.editingRow = id;
    this.isEditing = true;
    this.editIdDiscipline = id;
    this.disciplineInEditMode = discipline;
  }

  getUsersByDisciplineIdAndComplexId(disciplineId) {
    if (this.newIdDiscipline === undefined || !this.project ) return;
    this.newUserResponsibleIdDiscipline = '';
    this.usersSpecialist = [];
    this.selectedUserResponsibleIdDiscipline = 0;
    this.userService.getUsersByDisciplineAndComplex(this.project.complex?.id, disciplineId)
    .subscribe({
      next: (usersResponse) => this.usersSpecialist = usersResponse
    });
  }

  deleteDiscipline(assessmentIdToDelete: number) {
    this.disciplineAssessmentService
    .deleteDisciplineAssessment(assessmentIdToDelete)
    .subscribe({
      next: () => {
        this.updateDisciplines.emit(true);
        this.orderAssessmentsByRegistration();
      },
    });
  }

  isDeadLineDate(discipline: any): boolean {
    let currentDate = moment(new Date().setHours(0, 0, 0, 0)).utcOffset(0, true);
    let deadLineDate = moment(discipline.analysisDeadline).utcOffset(0, true);
    return currentDate > deadLineDate;
  }

  stopEditDiscipline(): void {
    this.removeAddedDisciplinedFromTheList();
    this.editingRow = null;
    this.editIdDiscipline = null;
    this.isEditing = false;
  }

  updateDiscipline() {

    let discipline = this.disciplineInEditMode;

    const requestBody = this.getRequestBody(discipline);

    this.disciplineAssessmentService.updateAssessment(requestBody).subscribe({
      next: () => {
        this.updateDisciplines.emit(true);
        this.message.showSucessMessage("Disciplina atualizada com sucesso.");
        this.refreshData();
      }, error: () => {
        this.message.showErrorMessage("Ocorreu um erro inesperado, por gentileza, tente novamente.");
        this.refreshData();
      }
    });
  }

  getDisciplines() {
    this.disciplinesService.getDisciplinesToScopeChange().subscribe({
      next: (response: any) => {
        this.disciplinesList = response.disciplines;
        this.removeAddedDisciplinedFromTheList();
      },
    });
  }

  orderAssessmentsByRegistration(){
    this.scopeChange.disciplineAssessments.sort((a, b) =>
      moment(a.registrationDate).diff(b.registrationDate)
    )
  }

  private removeAddedDisciplinedFromTheList() {
    this.scopeChange?.disciplineAssessments.forEach((element: any, i) => {
      const discipline = this.disciplinesList.filter(
        (disc) => disc.id === element.discipline.id
      );
      if (discipline.length > 0) {
        this.disciplinesList.splice(
          this.disciplinesList.indexOf(discipline[0]),
          1
        );
      }
    });
  }

  cancelNewDisciplineClick() {
    this.addNewDiscipline = false;
    this.newDateDeadlineDiscipline = undefined;
    this.newIdDiscipline = undefined;
    this.newStatusDiscipline = '';
    this.newUserResponsibleIdDiscipline = undefined;
  }

  addNewDisciplineDB() {
    if (!this.newIdDiscipline || !this.newUserResponsibleIdDiscipline || !this.newDateDeadlineDiscipline) {
      this.message.showErrorMessage("Campos obrigatorios não preenchidos");
      return;
    }
    const requestBody = this.buildDisciplineItems();

    this.projectsV2Service
    .addNewDisciplineToScopeChange(requestBody)
    .subscribe({
      next: () => {
        this.updateDisciplines.emit(true);
        this.orderAssessmentsByRegistration();
      },
    });

    this.refreshData();
  }

  addNewDisciplineClick() {
    this.addNewDiscipline = true;
    this.newStatusDiscipline = "EAN"
    this.newIdDiscipline = undefined;
    this.newUserResponsibleIdDiscipline = undefined;
    this.newDateDeadlineDiscipline = undefined;
    this.getDisciplines();
    this.stopEditDiscipline();
  }

  private buildDisciplineItems() {
    return {
      scopeChangeId: this.scopeChangeId,
      idDiscipline: this.newIdDiscipline,
      idUserAssessment: this.newUserResponsibleIdDiscipline,
      analysisSummary: "",
      requiresFieldVisit: true,
      analysisDeadline: this.utilService
      .DateFormatter(this.newDateDeadlineDiscipline)
      .split('T')[0],
    };
  }

  private refreshData() {
    this.getDisciplines();
    this.newDateDeadlineDiscipline = '';
    this.newIdDiscipline = '';
    this.newStatusDiscipline = '';
    this.newUserResponsibleIdDiscipline = '';
    this.addNewDiscipline = false;
    this.stopEditDiscipline();
  }

  private getRequestBody(discipline) {
    return {
      id: discipline.id,
      idDiscipline: this.selectedDisciplineId,
      requiresFieldVisit: true,
      idUserAssessment: this.selectedUserResponsibleIdDiscipline,
      analysisDeadline: this.editDateDeadlineDiscipline,
      status: discipline.status,
      costStudyList: discipline.costStudies,
      disciplineAssessmentQuestion: discipline.answeredQuestions
    };
  }

  goToDiscipline(id) {
    if (this.blockScopeChange) {
      return;
    }
    this.activatedRoute.parent?.params.subscribe(params => {
      const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
        projectId: params['projectId']
      });
      const routeEdit = buildRoute(RoutesEnum.SCOPE_CHANGE_ASSESSMENT, {
        scopeId: this.scopeChangeId,
        id: id,
      });
      this.router.navigate([`${routeProject}/requisicoes/${routeEdit}`]);

    });
  }


  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;



}
