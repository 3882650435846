<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <section class="app-scope-change-request sl__simpleLayout">
        <header>
            <div class="sl__simpleLayout__header__content">
                <button type="button" id="button-back" class="sl__simpleLayout__header__buttonBack" (click)="goBack()">
                  <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
                </button>
                <h1> {{ 'projects.scope-change.scope-change-title' | translate }}</h1>
            </div>

              <div *ngIf="cadScopeChange" class="sl__simpleLayout__header__nav">
                <app-button-icon-secondary
                    [id]="'btn-icon-secondary'"
                    [text]="'Cancelar'"
                    altText="{{ 'Ícone Revisão' }}"
                    (click)="cancel()"
                    (keypress)="cancel()"></app-button-icon-secondary>
                <app-button-icon-primary
                    [id]="'btn-icon'"
                    [icon]="'icon_analysis'"
                    [text]="'Reenviar para análise'"
                    altText="{{ 'Ícone Check' }}"
                    (click)="reSendAnalysis()"
                    (keypress)="reSendAnalysis()">
                    </app-button-icon-primary>
              </div>
          </header>

    <div class="outer__wrapper" (scroll)="onScroll()">
        <div nz-row [nzGutter]="[16, 16]">
            <div nz-col [nzSpan]="14" class="form">

                <app-request-review
                    *ngIf="scopeChange && scopeChange.subProjectReviewList"
                    [scopeChange]="scopeChange"
                    [scopeChangeId]="scopeChangeId"
                    [showActionButtons]="false">
                </app-request-review>


                <div class="round-border mb-16">
                    <app-detail-about-request-container
                        [checkValidateForm]="checkValidateForm"
                        [scopeChangeId]="scopeChangeId"
                        (handleFormChanges)="handleDetailAboutRequest($event)">
                    </app-detail-about-request-container>
                </div>

                <div class="round-border">
                    <app-about-project-container
                        [projectId]="projectId"
                        [isScopeChange]="true"
                        [attachments]="scopeChangeAttachments"
                        [currentValues]="formData"
                        [scopeChange]="scopeChange"
                        [project]="project"
                        #aboutProjectContainer
                        (formData)="handleFormChanges($event)">
                    </app-about-project-container>

                    <app-upload-files
                        #uploadFiles
                        [attachmentsId]="scopeChangeId"
                        entityName="SCOPE_CHANGE"
                        [disabledBlock]="false"
                        [instantUpload]="true">
                    </app-upload-files>
                </div>
            </div>

            <div nz-col [nzSpan]="10" class="scopeResume">
                <div class="round-border">
                    <app-resume-scope-change-request-container
                        [scopeSummary]="scopeSummary"
                        [projectSummary]="projectSummary">
                    </app-resume-scope-change-request-container>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
