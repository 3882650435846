import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/shared/util.service';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { SummaryScopeChangePublish } from '../../../interface/scope-change-publish-final';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { COMMUNICATION_ENTITY_NAME, SCOPE_CHANGE_FINAL_ENTITY_NAME } from 'src/app/modules/projects/constants/projectsNewConstants';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-publish-final-summary',
  templateUrl: './publish-final-summary.component.html',
  styleUrls: ['./publish-final-summary.component.scss']
})
export class PublishFinalSummaryComponent {

  @Input() summaryScopeChange!: SummaryScopeChangePublish;
  @Input() attachments: AttachmentsModel[];
  @Input() idScopeChange: number;

  constructor(
    private readonly utilService: UtilService,
    private attachmentsService: AttachmentsService,
  ) { }

 
  getIconFromFileName(filename: string) {
    return this.utilService.getIconFromFileName(filename);
  }

  getAttachmentTruncatedName(filename: string) {
    const name = filename.split('.')
    const filenameWithoutExtension = name.slice(0, name.length - 1).join('')
    const fileExtension = name[name.length - 1];

    if (filenameWithoutExtension.length > 30) {
      return filenameWithoutExtension.slice(0, 28) + '...' + fileExtension
    } else {
      return filename
    }
  }

  downloadFile = (file: AttachmentsModel): void => {
    let attach = this.utilService.buildNzUploadFileFromResponse(file);
    if (attach.uid === undefined) return;

    this.attachmentsService.getDownloadFile(+attach.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, attach.name),
    });
  };

}
