<div class="app-about-project-container">
<form nz-form [formGroup]="form" nzLayout="vertical" class="sl__form">
  <div (scroll)="onRemoveFocusInput()">
    <span id="hideListDropdown"></span>
      <h2 class="h2-title">{{ 'projects.title-about-project' | translate }}</h2>

      <app-badge-chips
        class="badge"
        badgeColor="warning"
        badgeText="projects.scope-change.warning-about-project"
        badgeIcon="icon_warning_neutral">
      </app-badge-chips>
      
      <!-- ICONE CUSTOMIZADO -->
      <ng-template #customIconTemplate>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
            stroke="#E9AB13" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.0502 17H11.9502" stroke="#E9AB13" stroke-width="2.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </ng-template>

      <div nz-row class="row-multiple-columns">
        <div class="ew">
          <nz-form-item
            [ngClass]="(formTouched && form.value?.projectName === '') || (formTouched && !form.value?.projectName) ? 'invalidSelector' : ''">
            <nz-form-label nzFor="projectName" nzRequired>
              <span class="sl-form-label">{{ 'projects.project-name' | translate }}</span>
            </nz-form-label>
            <nz-form-control>
              <nz-select  
                id="projectName"
                class="sl__form__inputFieldSelect inputFieldSelect"
                formControlName="projectName"
                [compareWith]="compareFn"
                [nzOpen]="isOpenSelectProject"
                #selectNomeProjeto
                nzShowSearch
                nzAllowClear
                [nzDisabled]="true"
                (ngModelChange)="getSubprojectDetails($event)"
                [nzSize]="'large'"
                [nzDropdownRender]="projectNameTemplate"
                [nzLoading]="isProjectListLoading"
                [nzPlaceHolder]="project?.name">
                <nz-option 
                  *ngFor="let item of filteredOptions" 
                  [nzLabel]="item.projectName ? (item.projectName && item.projectName.length > 40 ?
                              (item.projectName | slice:0:40) + '...' : item.projectName) : '-'"
                  [nzValue]="item.projectName + '_' + item.subProjectId">
                </nz-option>
              </nz-select>
              <ng-template #projectNameTemplate>
                <nz-divider></nz-divider>
                <div class="container">
                  <a class="add-item" style="margin-left: 10px; color: black" (click)="addNewProject(selectNomeProjeto)">
                    {{ 'commons.create-new' | translate }}
                  </a>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="ew">
          <nz-form-item class="sl__form__item">
            <nz-form-label nzFor="projectCode">
              <span class="sl-form-label project-code-label"> {{ 'projects.ripla.project-code-on-area-optional' | translate}}</span>
            </nz-form-label>
            <nz-form-control>
              <input 
                nz-input 
                id="txtCodeProject" 
                (blur)="handleFormChanges($event)" 
                [mask]="'A0000-00'" 
                [dropSpecialCharacters]="true"
                formControlName="projectCode" 
                placeholder="X0000-00"
                [style]="{ 'text-transform': 'uppercase' }">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

     <div nz-row class="row-multiple-columns">
        <div class="ew">
          <nz-form-item class="sl__form__item">
            <nz-form-label
              nzFor="areaSolicitante"
              nzRequired>
              <span class="sl-form-label">{{ 'commons.applicant-area' | translate}}</span>
            </nz-form-label>
            <nz-form-control class="about_project_form_control">
              <nz-select  id="areaSolicitante"
                          class="sl__form__inputFieldSelect inputFieldSelect"
                          formControlName="areaSolicitante"
                          [compareWith]="compareFn"
                          [nzOpen]="isOpenSelect"
                          #selectAreaSolicitante
                          nzShowSearch
                          nzAllowClear
                          [nzStatus]="form.controls['areaSolicitante'].invalid ? 'error' : ''"
                          (ngModelChange)="handleFormChanges($event)"
                          [nzSize]="'large'"
                          [nzDropdownRender]="renderTemplate"
                          nzPlaceHolder="{{ 'commons.select' | translate}}">
                <nz-option *ngFor="let item of resquestingAreasFiltered" [nzLabel]="item.description ? (item.description &&
                                                                           item.description.length > 40 ?
                                                                           (item.description | slice:0:40) + '...' : item.description) : '-'"
                           [nzValue]="item.id"></nz-option>
              </nz-select>
              <ng-template #renderTemplate>
                <nz-divider style="padding: 0px"></nz-divider>
                <div class="container">
                  <a class="add-item" style="margin-left: 10px; color: black" (click)="addAreaSolicitante(selectAreaSolicitante)">
                    {{ 'commons.create-new' | translate }}
                  </a>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="ew">
          <nz-form-item class="sl__form__item">
            <nz-form-label nzFor="areaSolicitante" nzRequired>
              <span class="sl-form-label">{{ 'projects.view.responsible-requesting-area' | translate }}</span>
            </nz-form-label>
            <nz-form-control class="about_project_form_control">
              <nz-select  id="selResponsavelAreaSolicitante" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                nzPlaceHolder="{{ 'commons.select' | translate}}" [compareWith]="compareFn" formControlName="responsavelAreaSolicitante"
                (ngModelChange)="handleFormChanges($event)" [nzLoading]="isUsersLoading" [nzStatus]="form.controls['responsavelAreaSolicitante'].invalid ? 'error' : ''">
                <nz-option *ngFor="let type of users" nzLabel="{{ type.name | titlecase }}"
                  nzValue="{{ type.id }}"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-multiple-columns mt-10">
        <div class="ew">
          <nz-form-item class="sl__form__item">
            <nz-form-label nzFor="projectClassification" nzRequired>
              <span class="sl-form-label">{{ 'projects.info.classification' | translate }}</span>
            </nz-form-label>
            <nz-form-control>
              <nz-radio-group 
                formControlName="projectClassification"
                (ngModelChange)="handleFormChanges($event);
                onSelectProjectClassificationGreen($event)"
                [nzDisabled]="isScopeChange">

                <label nz-radio [nzValue]="'Greenfield'" id="projectClassificationGreen">
                  <span style="display: flex;">
                    <span class="card--radio__span">Greenfield</span>
                    <img src="assets/icons/information-icon.svg"
                      alt="info"
                      nz-tooltip
                      nzTooltipPlacement="bottomLeft"
                      nzTooltipTitle="Projeto realizado em áreas que não possuem instalações e facilidades pré-existentes. Novos empreendimentos."
                      [nzTooltipArrowPointAtCenter]="false"
                      [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                  </span>
                </label>

                <label nz-radio [nzValue]="'Brownfield'" id="projectClassificationBrown">
                  <span style="display: flex;">
                    <span class="card--radio__span">Brownfield</span>
                    <img src="assets/icons/information-icon.svg"
                      alt="info"
                      nz-tooltip
                      nzTooltipPlacement="bottomLeft"
                      nzTooltipTitle="Projeto realizado em áreas que possuem instalações e facilidades às quais o produto do projeto será incorporado. Expansão de empreendimentos."
                      [nzTooltipArrowPointAtCenter]="false"
                      [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                  </span>
                </label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-multiple-columns mt-10 mb-10">
        <div class="ew">
          <app-input-textarea
            id="txtObservacao"
            [name]="'projects.brief-decriptiton-project' | translate"
            errorMessage="' "
            [styleResize]="'none'"
            [controls]="form.get('comments')"
            [required]="true"
            [status]="styleComments"
            (changeValue)="onSelectComments($event)">
      </app-input-textarea>
        </div>
      </div>

      <!-- LOCALIZACAO -->
      <h2 class="h2-title">{{ 'projects.view.location' | translate}}</h2>
      <div class="row-multiple-columns mt-20">
        <div class="ew">
          <nz-form-item>
          <nz-form-label nzFor="corredor" nzRequired>
            <span class="sl-form-label">{{ 'projects.corridor.title' | translate }}</span>
          </nz-form-label>
          <nz-form-control class="about_project_form_control">
            <nz-select id="selCorredor" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
              nzPlaceHolder="{{ 'commons.select' | translate}}" [compareWith]="compareFn" formControlName="corredor" (ngModelChange)="onChangeRegions($event)"
              [nzLoading]="isRegionsLoading" [nzStatus]="form.controls['corredor'].invalid && !sendDraft ? 'error' : ''" [nzDisabled]="isScopeChange">
              <nz-option *ngFor="let region of regions" nzLabel="{{ region.description }}"
                nzValue="{{ region.id }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        </div>

        <!-- COMPLEXO -->
        <div class="ew">
          <nz-form-item
            [ngClass]="(formTouched && form.value?.complexo === '') || (formTouched && !form.value?.complexo) ? 'invalidSelector' : ''">
            <nz-form-label nzFor="complexo" nzRequired>
              <span class="sl-form-label">{{ 'projects.complex.title' | translate }}</span>
            </nz-form-label>
            <nz-form-control class="about_project_form_control">
              <nz-select 
                id="selComplexo" 
                class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                [nzDisabled]="isScopeChange"
                nzPlaceHolder="{{ 'commons.select' | translate}}" 
                [compareWith]="compareFn" 
                formControlName="complexo" 
                (ngModelChange)="onChangeComplex($event);"
                [nzLoading]="isComplexesLoading" 
                [nzStatus]="form.controls['complexo'].invalid && !sendDraft ? 'error' : ''">
                <nz-option 
                *ngFor="let complex of complexes" nzLabel="{{ complex.description }}"
                  nzValue="{{ complex.id }}">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <!-- UNIDADE OPERACIONAL -->
        <div class="ew">
          <nz-form-item
            [ngClass]="(formTouched && form.value?.unidadeOperacional === '') || formTouched && !form.value?.unidadeOperacional ? 'invalidSelector' : ''">
            <nz-form-label nzFor="unidadeOperacional" nzRequired>
              <span class="sl-form-label">{{'projects.operationalUnit.title' | translate}}</span>
            </nz-form-label>
            <nz-form-control class="about_project_form_control">
              <nz-select 
                id="selUnidadeOperacional" 
                class="sl__form__inputFieldSelect inputFieldSelect" 
                [nzDisabled]="isScopeChange"
                [nzSize]="'large'" 
                nzAllowClear
                nzPlaceHolder="{{ 'commons.select' | translate}}" 
                [compareWith]="compareFn" 
                formControlName="unidadeOperacional" 
                (ngModelChange)="onSelectOperacionalUnits($event)"
                [nzLoading]="isOperacionalUnitLoading" 
                [nzStatus]="form.controls['unidadeOperacional'].invalid && !sendDraft ? 'error' : ''" 
                [nzDisabled]="form.controls['projectClassification'].value === 'Greenfield'">
                <nz-option *ngFor="let unit of operacionalUnits"
                  nzLabel="{{ unit.description }}"
                  nzValue="{{ unit.id }}">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
  </div>

      </form>
    </div>
