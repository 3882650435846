<div class="app-publish-final-summary summary__wrapper">
    
    <h2>Resumo do parecer</h2>

    <div class="summary__wrapper__items">
      <div class="summary__wrapper__items__item" id="resumoEscopo">
        
        <!-- Impacto Associados -->
        <div class="summary__wrapper__items__item_row">
          <p class="summary__wrapper__items__item_row__title">Impactos associados</p>
          <ul>
            <li *ngFor="let impact of summaryScopeChange?.impactAssociate">
              <span>&#x2022;</span>
              {{impact.description}}
            </li>
          </ul>
        </div>

        <!-- Estimativa de tempo -->
        <div class="summary__wrapper__items__item_row">
            <p class="summary__wrapper__items__item_row__title">Estimativa de tempo</p>
            <p class="summary__wrapper__items__item_row__description"> {{ summaryScopeChange?.timeEstimate }}</p>
        </div>

        <div class="summary__wrapper__items__item_row">
          <p class="summary__wrapper__items__item_row__title"> Descrição dos impactos associados </p>
          <p class="summary__wrapper__items__item_row__description"> {{ summaryScopeChange?.description }}</p>
        </div>
      </div>

      <!-- Anexos -->
      <div class="summary__wrapper__items__item" *ngIf="attachments && attachments.length > 0">
        <h3> Arquivos do parecer </h3>
        
        <div class="summary__wrapper__items__item_fileList">
          <div class="summary__wrapper__items__item_fileList_document mt-05" *ngFor="let attachment of attachments">
            <img src=" {{ getIconFromFileName(attachment.name) }}" alt="some file" />
            <div class="summary__wrapper__items__item_fileList_document__fileName" (click)="downloadFile(attachment)">
              {{ attachment.name.length > 40 ? (getAttachmentTruncatedName(attachment.name)) : (attachment.name) }}
            </div>
          </div>
        </div>
      </div>

    </div>
    
</div>
  