import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('appTooltip') tooltipText: string = '';
  @Input() tooltipWidth: string = 'auto';
  @Input() tooltipArrowPosition: 'top' | 'bottom' = 'top';
  @Input() tooltipContainerPositionIsBottom: boolean = false;
  @Input() tooltipArrowAlignment: 'left' | 'right' | 'center' = 'center';
  private tooltipElement: HTMLElement | null = null;
  private arrowElement: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltipElement) {
      this.createTooltip();
      this.createArrow();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  @HostListener('click') onClick() {
    this.hideTooltip();
  }

  private createTooltip() {
    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltipElement, this.renderer.createText(this.tooltipText));
    this.renderer.appendChild(this.el.nativeElement, this.tooltipElement);

    if (this.tooltipElement) {
      this.setStyle(this.tooltipElement, {
        position: 'absolute',
        background: '#252525',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '5px',
        top: this.tooltipArrowPosition === 'top' ? '180%' : '-60%',
        right: '-50%',
        transform: 'translateX(0%)',
        zIndex: '1000',
        wordBreak: 'break-word',
        width: this.tooltipWidth,
        minWidth: '86px',
        textAlign: 'center'
      });

      if(this.tooltipContainerPositionIsBottom){
        this.setStyle(this.tooltipElement, {
          top: '-260%',
          right: 'inherit',
          left: '-9px'
         });
      }
    }
    this.renderer.addClass(this.tooltipElement, 'tooltip');
  }

  private createArrow() {
    this.arrowElement = this.renderer.createElement('span');
    this.renderer.appendChild(this.tooltipElement!, this.arrowElement);

    if (this.arrowElement) {
      const arrowStyles = {
        position: 'absolute',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
      };

      if (this.tooltipArrowPosition === 'top') {
        Object.assign(arrowStyles, {
          top: '-9px',
          borderBottom: '10px solid #252525'
        });
      } else {
        Object.assign(arrowStyles, {
          bottom: '-9px',
          borderTop: '10px solid #252525'
        });
      }

      switch (this.tooltipArrowAlignment) {
        case 'left':
          Object.assign(arrowStyles, { left: '8px' });
          break;
        case 'right':
          Object.assign(arrowStyles, { right: '8px' });
          break;
        case 'center':
        default:
          Object.assign(arrowStyles, { left: '50%', transform: 'translateX(-50%)' });
          break;
      }

      this.setStyle(this.arrowElement, arrowStyles);
    }
  }

  private setStyle(element: HTMLElement, styles: { [key: string]: string }) {
    for (const [key, value] of Object.entries(styles)) {
      this.renderer.setStyle(element, key, value);
    }
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(this.el.nativeElement, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
