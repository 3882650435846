<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <section class="app-scope-change-request sl__simpleLayout">
        <header>
            <div class="sl__simpleLayout__header__content">
                <button type="button" id="button-back" class="sl__simpleLayout__header__buttonBack" (click)="goBack()">
                  <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
                </button>
                <h1> {{ 'projects.scope-change.scope-change-title' | translate }}</h1>
            </div>

              <div *ngIf="cadScopeChange" class="sl__simpleLayout__header__nav">
                <app-button-icon-secondary [id]="'btn-icon-secondary'" [text]="'Cancelar'"
                  altText="{{ 'Ícone Revisão' }}" (click)="onCancel()"></app-button-icon-secondary>
                <app-button-icon-secondary [id]="'btn-icon-secondary'" [icon]="'icon_review_info'" [text]="'Salvar Rascunho'"
                  altText="{{ 'Ícone Revisão' }}" (click)="saveDraft()"></app-button-icon-secondary>
                <app-button-icon-primary [id]="'btn-icon'" [icon]="'icon_check_small'" [text]="'Enviar Requisição'"
                  altText="{{ 'Ícone Check' }}" (click)="sendAnalysis()"></app-button-icon-primary>
              </div>
          </header>

    <div class="outer__wrapper" (scroll)="onScroll()">
        <div nz-row [nzGutter]="[16, 16]">
            <div nz-col [nzSpan]="14" class="form">
                <div class="round-border mb-16">
                    <app-detail-about-request-container
                        [checkValidateForm]="checkValidateForm"
                        [scopeChangeId]="scopeChangeId"
                        (handleFormChanges)="handleDetailAboutRequest($event)">
                    </app-detail-about-request-container>
                </div>

                <div class="round-border">
                    <app-about-project-container
                        [projectId]="projectId"
                        [isScopeChange]="true"
                        #aboutProjectContainer
                        [project]="project"
                        [currentValues]="formData"
                        (formData)="handleFormChanges($event)"
                        (initSummary)="setProjectSummary()"
                        [alertTip]="'Atualize abaixo as informações e documentos necessários. Essas novas informações não afetarão os dados originais do projeto até que o pedido de mudança de escopo seja concluído.'">
                    </app-about-project-container>

                    <app-upload-files
                        #uploadFiles
                        [attachmentsId]="scopeChangeId"
                        entityName="SCOPE_CHANGE"
                        [disabledBlock]="false"
                        [instantUpload]="true"
                        (filesEmit)="handleUploadChanges($event)">
                    </app-upload-files>

                </div>
            </div>

            <div nz-col [nzSpan]="10" class="scopeResume">
                <div class="round-border">
                    <app-resume-scope-change-request-container
                        [scopeSummary]="scopeSummary"
                        [projectSummary]="projectSummary"
                        [attachments]="attachmentSummary">
                    </app-resume-scope-change-request-container>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
