<div class="chat_ai-file-selection">
  <button (click)="closeModal()" class="close_button">
    <img src="assets/icons/icon_close_green.svg" alt="icon">
  </button>

  <section>
    <h3>{{'chat-ia.file-selection.title' | translate}}</h3>

    <div class="form_container" >
      <div class="form_item">
        <label><span class="mandatory-indicator">*</span>{{'chat-ia.file-selection.label.project' | translate}}</label>
        <p-dropdown
          [options]="projectList"
          [filter]="true"
          (onChange)="defineSelectedProject($event)"
          optionLabel="projectName"
          [placeholder]="'chat-ia.file-selection.placeholder.name' | translate" />
      </div>

      <div class="form_item">
        <label><span class="mandatory-indicator">*</span>{{'chat-ia.file-selection.label.file' | translate}}</label>
        <p-treeSelect
          [options]="projectAttachments"
          [(ngModel)]="selectedFiles"
          (ngModelChange)="checkSelectedFiles($event)"
          [placeholder]="'chat-ia.file-selection.placeholder.file' | translate"
          selectionMode="checkbox"
          class="treeselect_checkbox"
          display="chip"
          [metaKeySelection]="false"
          [filter]="true"
          [disabled]="selectedProject === undefined"
          [maxlength]="5"
        />
      </div>

      <div class="form_item action_buttons">
        <app-button-icon-secondary
          customClassBtn="padding: 0 16px;"
          [id]="'btn-icon-secondary'"
          [text]="'chat-ia.file-selection.placeholder.cancel' | translate"
          [altText]="'chat-ia.file-selection.placeholder.cancel' | translate"
          (click)="closeModal()" />

        <app-button-icon-primary
          customClassBtn="padding: 0 16px;"
          [id]="'btn-icon-secondary'"
          [text]="'chat-ia.file-selection.placeholder.select' | translate"
          [altText]="'chat-ia.file-selection.placeholder.select' | translate"
          [disabledBtn]="(selectedProject === null || selectedFiles.length === 0)"
          (click)="selectFiles()" />
      </div>
    </div>
  </section>
</div>
