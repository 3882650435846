import { createSelector, createFeatureSelector, select } from '@ngrx/store';
import { AttachmentsState } from './chat-ai.state.model';

export const selectAttachmentsState = createFeatureSelector<AttachmentsState>('attachments');

export const selectPages = createSelector(
  selectAttachmentsState,
  (state: AttachmentsState) => state.attachments
);

export const selectIsChatOpen = createSelector(
  selectAttachmentsState,
  (state: AttachmentsState) => state.isChatOpen
);

export const selectIsReloadChat = createSelector(
  selectAttachmentsState,
  (state: AttachmentsState) => state.isReloadChat
);
