import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-modal-period-date',
  templateUrl: './modal-period-date.component.html',
  styleUrl: './modal-period-date.component.scss'
})
export class GenericModalPeriodDateComponent {

  private _cleanValues: boolean = false;
  
  @Input() formatMask: string = 'YYYY-MM-DD'
   
  @Input()
  set cleanValues(value: boolean) {
    if (value !== this._cleanValues) {
      this._cleanValues = value;
      this.onClean();
    }
  };
  
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCleanValues = new EventEmitter<any>();
  
  inputForDate: any;
  inputForPeriod: any;
  minDate: Date | undefined;

  setMinDate(): void {
    if (this.inputForDate) {
      this.minDate = new Date(this.inputForDate);
    } else {
      this.minDate = undefined;
    }
  }

  onClean() {
    this.inputForDate = null;
    this.inputForPeriod = null;
    this._cleanValues = false;
    this.changeCleanValues.emit(true);
  }

  hasFilterValue(): boolean {
    return (this.inputForDate || this.inputForPeriod) ? true : false;
  }

  onApplyFilter() {
    let periodDate = this.inputForPeriod ? this.inputForPeriod : this.inputForDate;
    let date = moment(this.inputForDate).format(this.formatMask);
    let period = moment(periodDate).format(this.formatMask);

    if (date !== 'Invalid date' || period !== 'Invalid date') {
      this.changeValue.emit({ date: date, period: period});
    }
  }
}
