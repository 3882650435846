import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Salva um valor no localStorage
   * @param key A chave onde o valor será armazenado
   * @param value O valor a ser salvo (string, objeto, número, etc.)
 */
  setItem(key: string, value: any): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  }

  /**
   * Busca um valor no localStorage
   * @param key A chave do valor a ser buscado
   * @returns O valor desserializado ou null se não existir
  */
  getItem<T>(key: string): T | null {
    const serializedValue = localStorage.getItem(key);
    return serializedValue ? JSON.parse(serializedValue) : null;
  }


}
