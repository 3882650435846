import { Component, EventEmitter, Input, Output, TemplateRef, AfterViewInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';

/*
* isClickRowEvent: Habilita a função para emitir valor quando clicar na linha da tabela
*/

export interface GenericTableConfiguration {
  isCheckbox: boolean;
  isResizeColumn: boolean;
  isClickRowEvent: boolean;
  isEditableFirstColumn?: boolean;
  isDisableRow?: { field: string }
}

/*
* field: Nome do campo que será utilizado para renderizar o conteúdo na coluna
* subField: Nome do campo que será utilizado para renderizar o conteúdo na coluna, caso o campo seja um objeto
* templateName: Nome do template que será utilizado para renderizar o conteúdo de filtro na coluna
*
* Exemplo: (field + subField)
*  user: [{id: 1, name: 'abc', email: 'abc@email.com'}]
*  field: 'user', subField: 'name'
*/
export interface GenericTableHeader {
  field: string;
  subField?: string;
  label: string;
  templateName:  TemplateRef<any>;
  templateIcon?: TemplateRef<any>;
  isLink?: boolean;
  isBadge?: boolean;
  isCustomTooltipDescription?: boolean;
  disableTooltip?: boolean;
  behindSchedule?: boolean;
  isColor?: boolean;
  isEditable?: boolean;
}

// Remover após a criação da tabela com grid editável | components: (HistoricChatsComponents)
export interface InputEditableConfig {
  id: any,
  targetField: string, 
  editable: boolean
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.scss'
})
export class GenericTableComponent implements AfterViewInit, OnDestroy {

  @Input() dataConfiguration: GenericTableConfiguration = {isCheckbox: false, isResizeColumn: false, isClickRowEvent: false, isEditableFirstColumn: false};
  @Input() dataHeaders: GenericTableHeader[] = [];
  @Input() dataList: any[] = [];
  @Input() resetSelection: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() statusOptions: { label: string, description: string }[] = [];
  @Input() editableConfiguration: InputEditableConfig = {id: null, targetField: '' , editable: false};

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeHoverValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValueCol: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeEditRow: EventEmitter<any> = new EventEmitter<any>();

  selectedDataValues: any;

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.detectScrollEvent();
    });
  }

  ngOnDestroy(): void {
    const datatableWrapper = document.querySelector('.p-datatable-wrapper');
    datatableWrapper?.removeEventListener('scroll', this.detectScrollEvent);
  }

  detectScrollEvent(): void {
    const datatableWrapper = document.querySelector('.p-datatable-wrapper');
    if (datatableWrapper) {
      datatableWrapper.addEventListener('scroll', (event) => {
        const scrollTop = datatableWrapper.scrollTop;
        const clientHeight = datatableWrapper.clientHeight;
        const scrollHeight = datatableWrapper.scrollHeight;
        const tolerance = 2;
        if (scrollTop + clientHeight >= scrollHeight - tolerance) this.loadMore.emit();
      });
    }
  }

  clearSelection(): void {
    this.selectedDataValues = [];
  }
  
  resolveField(rowData: any, field: string, subField?: string): any {
    if (!rowData || !field) return null;

    const value = field.split('.').reduce((acc, key) => acc?.[key], rowData);

    if (Array.isArray(value)) {
      if (subField && typeof value[0] === 'object') {
        return value
          .map(item => item[subField] || JSON.stringify(item))
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      }
      return value.join(', ');
    }

    if (typeof value === 'object' && value !== null) {
      if (subField) return value[subField] !== undefined ? value[subField] : JSON.stringify(value);
      return JSON.stringify(value);
    }
    return (value !== undefined || value !== null) ? this.formatDate(value) : null;
  }

  formatDate(value: any): string | null {
    
    if (!value) return null;
    if (value instanceof Date) {
      const date = moment(value);
      return date.isValid() ? date.format('DD/MM/YYYY') : null;
    }
    const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD', 'DD/MM', 'MM/YYYY'], true);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY');
    }

    return value;
  }
  
  formatToMultiline(value: string | null | undefined, isCustomTooltipDescription: any): string[] | null {
    if (!value) return null;

    return value.split(',').map(item => {
      const trimmedItem = item.trim();
      return isCustomTooltipDescription ? this.getStatusDescription(trimmedItem) || trimmedItem : trimmedItem;
    }).sort((a, b) => a.localeCompare(b));
  }

  getStatusDescription(statusCode: string): string | null {
    const status = this.statusOptions.find(option => option.label === statusCode);
    return status ? status.description : null;
  }

  isDateBehindSchedule(rowData: any, field: string): boolean {
    const colum= this.dataHeaders.find(header => header.behindSchedule === true);
    if (!colum) return false;
    if (colum.field === field){

      const value = rowData[colum.field];
      const today = moment().startOf('day');
      const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
  
      return date.isValid() && !date.isSameOrAfter(today) && rowData.status !== 'CON';
    } else {
      return false;
    }
    
  }

  onChangeSelect(): void {
    this.changeValue.emit(this.selectedDataValues);
  }

  onRowClick(rowData: any): void {
    if (this.dataConfiguration.isClickRowEvent) {
      this.changeValue.emit(rowData);
    }
  }

  onRowHover(rowData: any) {
    if (this.dataConfiguration.isClickRowEvent) {
      this.changeHoverValue.emit(rowData);
    }
  }

  onColClick(rowData: any): void {
    this.changeValueCol.emit(rowData);
  }

  onChangeEditRow(rowData, event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === 'Escape') {
      this.changeEditRow.emit(rowData);
    }
  }

  onInputFocusOut(rowData): void {
    this.changeEditRow.emit(rowData);
  }

  isSelected(item: any): boolean {
    return this.selectedDataValues && this.selectedDataValues.length > 0 ? this.selectedDataValues.includes(item) : false;
  }

  onTableScroll(event: any) {}

  loadData(event: any) {}
  
  getColorForColumn(colorArray: { color: string; column: string }[] | undefined, column: string): string {
    if (!colorArray) return ''; 
    const colorObject = colorArray.find(colorObj => colorObj.column === column);
    return colorObject ? colorObject.color : '';
}

}
