import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { toNumber } from "ng-zorro-antd/core/util";
import { Observable, of } from "rxjs";
import { ProjectByIdResponseDto } from "src/app/shared/dto/project-by-id-response.dto";
import { ProjectsV2Service } from "src/app/shared/services/projects-v2.service";
import { ScopeChangeService } from "../service/scope-change/scope-change.service";
import { ScopeChangeResponse } from "../interface/scope-change-request";

@Injectable({
    providedIn: 'root'
})
export class ScopeChangeReviewResolver  {
    
    constructor(private projectV2Service: ProjectsV2Service,
        private scopeChangeService: ScopeChangeService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScopeChangeResponse> {
        const id = route.paramMap.get("scopeId")
        if (id)
            return this.scopeChangeService.getScopeChange(toNumber(id));
        else
            return of();
    }
}