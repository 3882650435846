import { ActivatedRoute, Router } from '@angular/router';

import { Component, Input } from '@angular/core';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-final-opinion-me',
  templateUrl: './final-opinion-me.component.html',
  styleUrls: ['./final-opinion-me.component.scss'],
})
export class FinalOpinionMeComponent {
  @Input() scopeChangeId: number;
  @Input() scopeData: any;
  @Input() opinionStatus: 'HIDE' | 'PENDING' | 'CONSOLIDATE' | 'DRAFT' | 'EDIT' | 'DONE';
  @Input() editFinalOpnion?: boolean = true;
  @Input() isClosedOrCancelled: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  goToFinalOpinion() {
    this.activatedRoute.parent?.params.subscribe(params => {

      const routeProject = buildRoute(RoutesEnum.SCOPE_CHANGE, {
        projectId: `${params['projectId']}`
      });

      const routeChangePublish= buildRoute(RoutesEnum.SCOPE_CHANGE_PUBLISH, {
        id: `${this.scopeChangeId}`
      });

      this.router.navigate([`${routeProject}/${routeChangePublish}`]);
    });
  }
}
