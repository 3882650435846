<section
  class="requesting-area-conf-container round-border"
  *ngIf="!isConcludedOrCancelled()"
>
  <h2 class="sl__simpleLayout__card__title">
    {{ "projects.scope-change.confirmation-title" | translate }}
  </h2>

  <div class="deadline_container">
    <span class="deadline_label">{{
      "projects.scope-change.confirmation-deadline" | translate
    }}</span>
    <section class="date_and_hint_container">
      <span [ngClass]="getDateColorClass()">{{ getDeadlineDate() }}</span>
      <span
        nzTooltipTitle="Requisições sem resposta em até 120 dias serão canceladas e arquivadas."
        nzTooltipPlacement="bottom"
        nz-tooltip
      >
        <img
          src="../../../../../../assets/icons/information-icon.svg"
          width="12"
          height="12"
          alt="Informação"
        />
      </span>
    </section>
  </div>

  <app-input-textarea
    *ngIf="isUserDemandant && !isExpired"
    [id]="'input-text-area-detail'"
    [name]="'Comentários finais'"
    class="final_comments_input_container"
    placeholder="Adicione comentários"
    errorMessage=""
    [status]="' '"
    [styleResize]="'none'"
    [required]="false"
    [controls]="form.get('finalComments')"
    (changeValue)="onChangeFinalComments($event)"
  >
  </app-input-textarea>

  <app-information-banner
    [bannerText]="getBannerText()"
    [iconName]="isExpired ? 'icon_warning_alt.svg' : 'icon_warning_orange.svg'"
    [bannerColor]="isExpired ? 'red' : 'yellow'"
  ></app-information-banner>

  <section
    *ngIf="isUserDemandant && !isExpired"
    class="confirmation_action_section"
  >
    <span class="section_title">{{
      "projects.scope-change.confirmation-message" | translate
    }}</span>

    <div *ngIf="confirmScopeChange" class="action_buttons_container">
      <app-button-icon-secondary 
        text="Cancelar mudança" 
        (click)="openCancelationModal()">
      </app-button-icon-secondary>

      <app-button-icon-primary 
        *ngIf="isExistDisciplinesEan()"
        text="Confirmar mudança" 
        [icon]="'icon_check_small'" 
        (click)="scopeChangeConclusion()">
      </app-button-icon-primary>
    </div>
  </section>
</section>

<section
  class="requesting-area-conf-container round-border"
  *ngIf="isConcludedOrCancelled()"
>
  <h2 class="sl__simpleLayout__card__title">
    {{ "projects.scope-change.confirmation-title" | translate }}
  </h2>

  <div class="confirmation_info">
    <section
      *ngFor="let info of confirmationInfo"
      class="confirmation_info_container"
    >
      <span class="confirmation_info_key">{{ info.key | translate }}</span>
      <span class="confirmation_info_value">{{ info.value }}</span>
    </section>
  </div>
</section>
