<div class="app-scope-change-publish-final sl__simpleLayout">

    <header>
      <div class="sl__simpleLayout__header__content">
          <button type="button" id="button-back" (click)="onReturn()">
              <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
          </button>

          <h1>Parecer sobre Requisição de mudança de escopo</h1>
      </div>

      <!--  Header Botões  -->
      <div class="sl__simpleLayout__header__nav">
        <app-button-icon-secondary
          *ngIf=" scopeChange?.finalSubmissionDate === null && scopeChange?.status !== 'CON'"
          [id]="'btn-icon-secondary'"
          [icon]="'icon_save'"
          [text]="'Salvar rascunho'"
          altText="{{ 'Salvar rascunho' }}"
          (click)="onSaveDraft()">
        </app-button-icon-secondary>

        <app-button-icon-primary
          *ngIf="consolidationScopeChange"
          [id]="'btn-icon'"
          [icon]="'icon_check_small'"
          [text]="'Publicar parecer'"
          altText="{{ 'Publicar parecer' }}"
          (click)="onSave()">
        </app-button-icon-primary>
      </div>
    </header>

    <div class="outer__wrapper" (scroll)="onScroll()">
      <!-- BODY -->
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col [nzSpan]="14">
          <div class="round-border">
            <app-publish-final-form
              #finalForm
              [checkValidateForm]="checkValidateForm"
              [idScopeChange]="idScopeChangeParam"
              (handleFormChanges)="handleFormChanges($event)"
              (handleScopeChange)="getScopeChange($event)"
              (handleUploadFiles)="handleAttachmentsSummary($event)">
            </app-publish-final-form>
          </div>
        </div>

        <div nz-col [nzSpan]="10">
          <div class="round-border">
            <app-publish-final-summary
              [summaryScopeChange]="summaryScopeChange"
              [idScopeChange]="idScopeChangeParam"
              [attachments]="attachments">
            </app-publish-final-summary>
          </div>
        </div>
      </div>
    </div>

  </div>
