import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// ZORRO MODULES
import {NgxMaskDirective,  NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

// SMART LICENSING MODULES
import { SharedModule } from 'src/app/shared/shared.module';
import { ScopeChangeRoutingModule } from './scope-change-routing.module';
import { InputsLicensingModule } from 'src/app/shared/components/inputs/inputs-licensing.module';
import { ButtonsLicensingModule } from 'src/app/shared/components/buttons/buttons-licensing.module';
import { SelectsLicensingModule } from 'src/app/shared/components/selects/selects-licensing.module';

// COMPONENTS
import { MainComponent } from './view/main/main.component';
import { RequestReviewComponent } from './components/request-review/request-review.component';
import { FinalOpinionMeComponent } from './components/final-opinion-me/final-opinion-me.component';
import { ScopeChangeReviewComponent } from './view/scope-change-review/scope-change-review.component';
import { ScopeChangeRequestComponent } from './view/scope-change-request/scope-change-request.component';
import { ScopeChangeAnalysisComponent } from './view/scope-change-analysis/scope-change-analysis.component';
import { RequestReviewModalComponent } from './components/request-review-modal/request-review-modal.component';
import { FinalOpinionResumeComponent } from './components/final-opinion-resume/final-opinion-resume.component';
import { AnalysisInfoComponent } from './components/scope-change-analysis-view/analysis-info/analysis-info.component';
import { ScopeChangePublishFinalComponent } from './view/scope-change-publish-final/scope-change-publish-final.component';
import { AnalysisHistoryComponent } from './components/scope-change-analysis-view/analysis-history/analysis-history.component';
import { AnalysisRequiredComponent } from './components/scope-change-analysis-view/analysis-required/analysis-required.component';
import { PublishFinalFormComponent } from './components/scope-change-publish-final-view/publish-final-form/publish-final-form.component';
import { AboutProjectContainerComponent } from './components/scope-change-form/about-project-container/about-project-container.component';
import { ScopeChangeDisciplineAssessmentComponent } from './view/scope-change-discipline-assessment/scope-change-discipline-assessment.component';
import { PublishFinalSummaryComponent } from './components/scope-change-publish-final-view/publish-final-summary/publish-final-summary.component';
import { DisciplineAssessmentComponent } from './components/scope-change-discipline-assessment/discipline-assessment/discipline-assessment.component';
import { DetailAboutRequestContainerComponent } from './components/scope-change-form/detail-about-request-container/detail-about-request-container.component';
import { RequestingAreaConfirmationComponent } from './components/scope-change-analysis-view/requesting-area-confirmation/requesting-area-confirmation.component';
import { HistoryRequestReviewContainerComponent } from './components/scope-change-form/history-request-review-container/history-request-review-container.component';

const MODULES = [
  SharedModule,
  NzGridModule,
  NzFormModule,
  NzWaveModule,
  NzMenuModule,
  NzInputModule,
  NzRadioModule,
  NzTableModule,
  NzButtonModule,
  NzSelectModule,
  NzUploadModule,
  NzPopoverModule,
  NzDividerModule,
  NzToolTipModule,
  NzCheckboxModule,
  NzDropDownModule,
  NzDatePickerModule,
  NzAutocompleteModule,
  InputsLicensingModule,
  ButtonsLicensingModule,
  SelectsLicensingModule,
  NgxMaskDirective,
  NgxMaskPipe,
];

const COMPONENTS = [
  MainComponent,
  AnalysisInfoComponent,
  RequestReviewComponent,
  FinalOpinionMeComponent,
  AnalysisHistoryComponent,
  AnalysisRequiredComponent,
  PublishFinalFormComponent,
  ScopeChangeReviewComponent,
  ScopeChangeRequestComponent,
  RequestReviewModalComponent,
  FinalOpinionResumeComponent,
  ScopeChangeAnalysisComponent,
  PublishFinalSummaryComponent,
  DisciplineAssessmentComponent,
  AboutProjectContainerComponent,
  ScopeChangePublishFinalComponent,
  RequestingAreaConfirmationComponent,
  DetailAboutRequestContainerComponent,
  HistoryRequestReviewContainerComponent,
  ScopeChangeDisciplineAssessmentComponent,
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: [ provideNgxMask()],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ScopeChangeRoutingModule,
    SharedModule,
    [...MODULES],
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NzAlertModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ScopeChangeModule {}
