import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import {FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {take} from 'rxjs';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {ComplexModel} from 'src/app/shared/models/complex.model';
import {OperacionalUnitModel} from 'src/app/shared/models/operacionalUnit.model';
import {Projects} from 'src/app/shared/models/projects-v2.model';
import {RegionModel} from 'src/app/shared/models/region.model';
import {RequestingArea} from 'src/app/shared/models/requestingArea.model';
import {UserModel} from 'src/app/shared/models/user.model';
import {ProjectsV2Service} from 'src/app/shared/services/projects-v2.service';
import {UserService} from 'src/app/shared/services/user.service';
import {UtilService} from 'src/app/shared/util.service';
import { ScopeChangeService } from '../../../service/scope-change/scope-change.service';
import { ScopeChangeResponse } from '../../../interface/scope-change-request';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-project-container',
  templateUrl: './about-project-container.component.html',
  styleUrls: ['./about-project-container.component.scss']
})
export class AboutProjectContainerComponent implements OnInit, OnChanges {
  @Output() formData = new EventEmitter<any>(); //CHANGE ANY FOR MODEL
  @Output() initSummary = new EventEmitter<any>();
  @Input() isDraftMode: boolean = false;
  @Input() sendDraft: boolean = true;
  @Input() data: any;
  @Input() isScopeChange: boolean;
  @Input() project!: any;
  @Input() scopeChange: ScopeChangeResponse | null;
  @Input() alertTip?: string;
  @Input() projectId: number;
  @Input() currentValues?: FormGroup;
  subProjectId;
  recoverProject = false; //CHECK

  form!: UntypedFormGroup;
  users: UserModel[] = [];
  options: Projects[] = [];
  resquestingAreas: RequestingArea[] = [];
  regions: RegionModel[] = [];
  complexes: ComplexModel[] = [];
  operacionalUnits: OperacionalUnitModel[] = [];

  isProjectListLoading: boolean = false;
  isUsersLoading: boolean = false;
  isRegionsLoading: boolean = false;
  isComplexesLoading: boolean = false;
  isOperacionalUnitLoading: boolean = false;
  riplaTypeDescription: string = '';
  scopeChangeId: number | null = null;

  inputValue?: string;
  inputValueAreaSolicitante?: string;
  filteredOptions: any[] = [];
  resquestingAreasFiltered: any[] = [];
  originalRegion;
  originalComplex;
  originalOperationalUnit;
  operationalUnit;
  private projectToCreateRipla: any;
  formTouched = false; //CHECK

  // CHECK - consider moving to an helper or component
  isOpenSelect: boolean;
  isOpenSelectProject: boolean;

  styleComments: string =  ''

  regex = '/[A-Za-z]+[0-9]+([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/i';
  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly projectsV2Service: ProjectsV2Service,
    private activatedRoute: ActivatedRoute,
    private readonly utilService: UtilService,
    private readonly i18n: NzI18nService,
    private readonly userService: UserService,
    private readonly message: MessageService,
    private scopeChangeService: ScopeChangeService
  ) {
    this.i18n.setLocale(pt_BR);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ((this.scopeChangeId && changes['scopeChange']?.currentValue) || changes['project']?.currentValue) {
      this.initForm();
    }
  }

  ngOnInit(): void {
    this.scopeChangeId = Number(this.activatedRoute.snapshot?.paramMap.get('scopeId'));

    if (!this.form) this.initializeEmptyForm();
  }

  initializeEmptyForm() {
    this.form = this.fb.group({
      projectName: ["", [Validators.required, Validators.minLength(3)]],
      projectCode: [""],
      projectNumber: [""],
      areaSolicitante: ["", [Validators.required]],
      responsavelAreaSolicitante: ["", [Validators.required]],
      projectClassification: ["", [Validators.required]],
      comments: ["", [Validators.required, Validators.maxLength(2000)]],
      corredor: ["", [Validators.required]],
      complexo: ["", [Validators.required]],
      unidadeOperacional: ["", [Validators.required]],
    });
  }
  initForm(): void {
    if (!this.form) this.initializeEmptyForm();

    let projectName = this.project?.name ? this.project?.name + "_" + this.project.id : '';
    let projectCode = this.scopeChange?.code ? this.scopeChange?.code : this.project?.code ? this.project?.code : '';
    let projectNumber = this.scopeChange?.number ? this.scopeChange?.number : this.project?.number ? this.project?.number : '';
    let areaSolicitante = this.scopeChange?.area ? this.scopeChange?.area.id.toString() :
      this.project?.area ? this.project?.area.id.toString() : '';
    let responsavelAreaSolicitante = this.scopeChange?.userLegalRepresentative ? this.scopeChange?.userLegalRepresentative.id :
      this.project?.userLegalRepresentative ? this.project?.userLegalRepresentative.id : '';
    let projectClassification = this.scopeChange?.classificationType ? this.utilService.formatString(this.scopeChange?.classificationType) :
      this.project?.classificationType ? this.utilService.formatString(this.project?.classificationType) : '';
    let comments = this.scopeChange?.comments ? this.scopeChange?.comments :
      this.project?.comments ? this.project.comments : '';
    let corredor = this.returnCorredorInitForm();
    let complexo = this.returnComplexInitForm();
    let unidadeOperacional = this.returnOperationalUnitInitForm();

    this.inputValue = projectName;
    this.inputValueAreaSolicitante = areaSolicitante;
    this.originalComplex = complexo
    this.originalRegion = corredor
    this.originalOperationalUnit = unidadeOperacional;

    if (!this.isScopeChange) {
      this.getProjects()
    }
    this.getRequestingAreas()
    this.getRegions()
    this.getUsers()

    if ((corredor && complexo) && (corredor !== '' && complexo !== '')) {
      this.onChangeRegions(parseInt(corredor.toString()));
      this.onChangeComplex(parseInt(complexo.toString()));
    }

    this.form = this.fb.group({
      projectName: [projectName, [Validators.required, Validators.minLength(3)]],
      projectCode: [projectCode],
      projectNumber: [projectNumber],
      areaSolicitante: [areaSolicitante, [Validators.required]],
      responsavelAreaSolicitante: [responsavelAreaSolicitante, [Validators.required]],
      projectClassification: [projectClassification, [Validators.required]],
      comments: [comments, [Validators.required, Validators.maxLength(2000)]],
      corredor: [corredor, [Validators.required]],
      complexo: [complexo, [Validators.required]],
      unidadeOperacional: [unidadeOperacional, [Validators.required]],
    });

    if(this.isScopeChange){
      this.form.get('projectClassification')?.disable();
      this.form.get('corredor')?.disable();
      this.form.get('complexo')?.disable();
      this.form.get('projectName')?.disable();
    }

    if (this.project?.projectClassification == "GREENFIELD" || this.scopeChange?.classificationType == 'GREENFIELD') {
      let regioId = this.scopeChange?.complex?.region ? this.scopeChange?.complex?.region?.id :
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
      this.projectsV2Service
      .getComplexById(regioId)
      .pipe(take(1))
      .subscribe((complex: any) => {
        this.complexes = complex;
        this.handleFormChanges("initing_form");
        this.initSummary.emit();
      })
    } else {
      let idComplex = this.isScopeChange ? this.scopeChange?.complex?.id : this.project?.complex?.id;
      if (idComplex) {
        this.projectsV2Service
        .getOperationalUnits(idComplex)
        .pipe(take(1))
        .subscribe((operacionalUnits: any) => {
          this.operacionalUnits = operacionalUnits;
          this.handleFormChanges("initing_form");
          this.initSummary.emit();
        })
      } else {
        this.handleFormChanges("initing_form");
      }
    }
  }

  returnComplexInitForm(): any {
    if (this.scopeChangeId) {
      return this.scopeChange?.complex ? this.scopeChange?.complex.id : '';
    } else {
      return this.project?.complex ? this.project?.complex.id : '';
    }
  }

  returnCorredorInitForm(): any {
    if (this.scopeChangeId) {
      return this.scopeChange?.complex?.region ? this.scopeChange?.complex?.region.id : '';
    } else {
      return this.project?.complex?.region ? this.project?.complex?.region.id : '';
    }
  }

  returnOperationalUnitInitForm(): any {
    if (this.scopeChangeId) {
      return this.scopeChange?.operationalUnit ? this.scopeChange?.operationalUnit.id : '';
    } else {
      return this.project?.operationalUnit ? this.project?.operationalUnit.id : '';
    }
  }

  buildPayload() {
    let projectId;
    const areaDescription = this.resquestingAreasFiltered.filter(area => area.id == this.form.controls['areaSolicitante'].value)?.[0]?.description
    if (this.filteredOptions && this.filteredOptions.length > 0) {
      const projects = this.options.filter(area => area.name === this.form.controls['projectName'].value.split("_")[0])
      if (projects.length > 0) {
        projectId = projects[0].projectId;
      }
    }
    let projectCode = this.form.controls['projectCode'].value.replace("-", "");
    if (projectCode !== '') {
      projectCode = projectCode.substr(0, 5) + '-' + projectCode.substr(5, 2)
    }
    let userName: string | undefined = '';
    if(this.form.controls['responsavelAreaSolicitante'].value){
      userName = this.users.filter(user => user.id == this.form.controls['responsavelAreaSolicitante'].value)?.[0]?.name;
    }
    let corredor: string | null = '';
    let complexo : string | null = '';
    let unidadeOperacional: string | null = '';
    if (this.form.controls['corredor'].value && this.regions.length > 0) {
      corredor = this.regions.filter(region => region.id == this.form.controls['corredor'].value)[0].description || null
    } else {
      corredor = '-'
    }
    if (this.complexes.length > 0 && this.form.controls['complexo'].value) {
      complexo = ' / ' + this.complexes.filter(complex => complex.id == this.form.controls['complexo'].value)[0].description || null
    } else {
      complexo = ''
    }
    if (this.form.controls['unidadeOperacional'].value && this.operacionalUnits.length > 0) {
      unidadeOperacional = ' / ' + this.operacionalUnits.filter(unit => unit.id == this.form.controls['unidadeOperacional'].value)[0].description || null
    } else {
      unidadeOperacional = ''
    }

    let location = (corredor ? corredor : '') + (complexo ? complexo : '') + (unidadeOperacional ? unidadeOperacional : '');

    return {
      projectName: this.form.controls['projectName'].value.split("_")[0],
      projectCode: projectCode,
      projectNumber: this.form.controls['projectNumber'].value,
      projectId: projectId ? projectId : null,
      areaId: this.form.controls['areaSolicitante'].value,
      areaDescription: areaDescription,
      userLegalRepId: this.form.controls['responsavelAreaSolicitante'].value,
      userLegalName: userName,
      location: location,
      classificationType: this.form.controls['projectClassification'].value === '' ? null : this.form.controls['projectClassification'].value.toUpperCase(),
      comments: this.form.controls['comments'].value,
      corridorId: this.form.controls['corredor'].value,
      complexId: this.form.controls['complexo'].value,
      operationalUnitId: this.form.controls['unidadeOperacional'].value === -1 ? null : this.form.controls['unidadeOperacional'].value,
      type: this.riplaTypeDescription.toUpperCase(),
      status: "INI"
    };
  }

  getProjectById(entityId): Promise<void> {
    return new Promise((resolve) => this.projectsV2Service.getProjectByProjectId(entityId).pipe().subscribe(data => {
      if (data) {
        this.project = data;
        this.project.subProjectId = data.id;
        this.riplaTypeDescription = this.project.classificationType;
        if(this.isScopeChange){
          this.filteredOptions.push(this.project)
        }
        this.changeEditOnRecoveredProject();
        resolve();
      }
    }))
  }

  getSubprojectDetails($event) {
    if ($event) {
      if ($event.split("_")[1] && $event.split("_")[1] != 0) {
        this.subProjectId = $event.split("_")[1]
        this.recoverProject = true
        if (!this.isScopeChange) {
          this.getProjectById(this.subProjectId);
        }
      }
    }
  }

  changeEditOnRecoveredProject() {
    this.project.projectCode ? this.form.controls['projectCode'].setValue(this.project.protocolNumber) : '';
    this.project.projectClassification ? this.form.controls['projectClassification'].setValue(this.utilService.formatString(this.project.classificationType)) : '';
    this.project.userLegalRepresentative ? this.form.controls['responsavelAreaSolicitante'].setValue(this.project.userLegalRepresentative.id) : '';
    this.project.area ? this.form.controls['areaSolicitante'].setValue(this.project.area.id) : '';
    this.project.comments ? this.form.controls['comments'].setValue(this.project.comments) : '';

    this.form.controls['corredor'].setValue(this.project.complex.region.id)
    this.form.controls['projectName'].setValue(this.project.name + '_' + this.project.subProjectId)
    this.form.controls['complexo'].setValue(this.project.complex.id)
    this.form.controls['unidadeOperacional'].setValue(this.project.operationalUnit?.id);
    this.project.userLegalRepresentative ? this.form.controls['responsavelAreaSolicitante'].setValue(this.project.userLegalRepresentative.id) : '';
    if (this.project.projectClassification === "GREENFIELD") {
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
    }

    this.enableDisableFields(false);
  }

  getProjects(): void {
    this.projectsV2Service
      .getProjects(1000)
      .pipe(take(1))
      .subscribe((items) => {
        if (items) {
          this.options = items.content;
          if (this.options && this.options.length > 0) {
            this.filteredOptions = this.options.filter(pro => pro.status === 'CON');
            if (this.isDraftMode) {
              const projetoASerEditado: Projects = {
                complex: "", corridor: "", operationalUnit: "",
                projectId: this.project?.id, registrationDate: "",
                name: this.project?.name,
                status: this.project?.status, subProjectId: this.project?.subProjectId,
                projectName: this.project?.projectName, edit: true
              }
              this.filteredOptions.push(projetoASerEditado)
              this.options.push(projetoASerEditado)
            }
            if (this.projectToCreateRipla) {
              let filteredProj = this.filteredOptions.filter(proj => proj.projectId == this.projectToCreateRipla.id)[0]
              if (filteredProj != null) {
                let projectValue = this.projectToCreateRipla.name + "_" + filteredProj.subProjectId
                this.form.controls['projectName'].setValue(projectValue)
              }
            }
          } else {
            this.options = []
            this.filteredOptions = []
          }
        }
      })
  }

  getRequestingAreas(): void { //CHACK
    this.projectsV2Service
      .getAreas()
      .pipe(take(1))
      .subscribe((areas: any) => {
        this.resquestingAreas = areas;
        this.resquestingAreasFiltered = areas;
      })
  }

  getRegions(): void {
    this.projectsV2Service
      .getRegions()
      .pipe(take(1))
      .subscribe((regions: any) => {
        this.regions = regions;
      })
  }

  private getUsers() {
    this.isUsersLoading = true;
    this.userService
      .getUsersByRole('ROLE_DEMANDANTE')
      .pipe(take(1))
      .subscribe((users) => {
        this.users = users;
        this.isUsersLoading = false;
      });
  }

  onChangeRegions(idRegion: number): void {
    if (!this.isDraftMode) {
      this.complexes = [];
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.form.get('complexo')?.reset();
    }
    if (this.isDraftMode && this.originalRegion !== idRegion) {
      this.complexes = [];
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.form.get('complexo')?.reset();
    }
    if (idRegion) {
      this.projectsV2Service
        .getComplexById(idRegion)
        .pipe(take(1))
        .subscribe((complex: any) => {
          this.complexes = complex;
        })
    }
  }

  onChangeComplex(idComplex: number): void {
    if (!this.isDraftMode) {
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
    }
    if (this.isDraftMode && this.originalComplex !== idComplex) {
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
    }
    if (idComplex) {
      this.projectsV2Service
        .getOperationalUnits(idComplex)
        .pipe(take(1))
        .subscribe((operacionalUnits: any) => {
          if (this.form.controls['projectClassification'].value !== 'Greenfield') {
            this.operacionalUnits = operacionalUnits;
          } else {
            this.form.controls['unidadeOperacional'].setValue(-1);
          }
        })
    }

    if (this.currentValues?.controls['complexId'].value != idComplex) {
      this.handleFormChanges("changing_complex");
    }
  }

  private enableDisableFields(enable: boolean) {
    if (enable) {
      this.form.get('projectName')?.enable()
    } else {
      this.form.get('projectName')?.disable()
    }
  }

  addAreaSolicitante(selectAreaSolicitante) { //CHECK - Consider moving to a component/helper to reuse?
    if (!selectAreaSolicitante.searchValue) return

    const newAreaRequest = {
      description: selectAreaSolicitante.searchValue
    }
    const filter = this.resquestingAreasFiltered.filter(area => area.description === newAreaRequest.description);
    if (filter.length > 0) {
      this.message.showErrorMessage("Existe uma área com o mesmo nome.")
      return;
    }
    this.projectsV2Service
      .createArea(newAreaRequest)
      .pipe(take(1))
      .subscribe((area: any) => {
        this.resquestingAreasFiltered.push(area);
        this.form.controls['areaSolicitante'].setValue(area.id);
        this.isOpenSelect = false;
      })
  }

  handleFormChanges($event?) {
    this.formData.emit(this.buildPayload());
    this.initSummary.emit();
  }

  onSelectOperacionalUnits($event) {
    if ($event) {
      if ($event !== this.currentValues?.controls['operationalUnitId'].value ||
        !this.currentValues?.controls['operationalUnitId'].value
      ) {
        this.handleFormChanges("changing_operational_unit");
      }
    }
  }

  onSelectProjectClassificationGreen($event) {
    if ($event) {
      if ($event == "Greenfield") {
        this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
        this.form.controls['unidadeOperacional'].setValue(-1);
      } else {
        this.getOperationalUnitsByComplexId(this.form.controls['complexo'].value)
        this.form.controls['unidadeOperacional'].setValue(this.scopeChange?.operationalUnit ? this.scopeChange?.operationalUnit.id :
          this.project?.operationalUnit ? this.project?.operationalUnit.id : '');
        this.operacionalUnits = this.operacionalUnits.filter(unit => unit.id != -1);
      }
      this.handleFormChanges("selecting_greenfield")
    }
  }

  getOperationalUnitsByComplexId(complextId: number): void {
    this.projectsV2Service
    .getOperationalUnits(complextId)
    .pipe(take(1))
    .subscribe((operacionalUnits: any) => {
      this.operacionalUnits = operacionalUnits;
    })
  }

  addNewProject(selectedValue: any) {
    const inputValue = selectedValue.searchValue
    if (!inputValue || inputValue?.length <= 0) {
      return;
    }
    this.setNewProjectValues(inputValue, true);
  }

  private setNewProjectValues(inputValue, nameOK: boolean) {
    if (!nameOK) {
      this.message.showErrorMessage("Nome do projeto já existe, por gentileza, altere o nome e tente novamente");
      return;
    }

    if (this.options.some(pro => pro.new || pro.edit)) {
      this.options.forEach(pro => {
        if (pro.new || pro.edit) {
          pro.projectName = inputValue;
        }
      })
      this.setSelectValue(inputValue + "_0");
      return;
    }
    if (this.filteredOptions.some(pro => pro.new || pro.edit)) {
      this.filteredOptions.forEach(pro => {
        if (pro.new || pro.edit) {
          pro.projectName = inputValue;
        }
      })
      this.setSelectValue(inputValue + "_0");
      return;
    }
    const novoProjeto: Projects = {
      complex: "",
      corridor: "",
      operationalUnit: "",
      projectId: 0,
      registrationDate: "",
      status: "",
      subProjectId: 0,
      name: inputValue,
      projectName: inputValue,
      new: true
    }
    this.options.push(novoProjeto);
    this.filteredOptions.push(novoProjeto);
    this.setSelectValue(inputValue + "_0");
  }

  setSelectValue(inputValue) {
    this.isOpenSelectProject = false;
    this.form.controls['projectName'].setValue(inputValue);
    this.handleFormChanges('changing_value');
  }

  showInvalidFormMensages() {
    this.message.showErrorMessage("Preencha todos os campos obrigatórios antes de salvar!");
    Object.values(this.form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
  }

  checkValidProjectCode() {
    let projectCode = this.form.controls['projectCode'].value.toUpperCase();
    const codWithMask = projectCode.substr(0, 5) + "-" + projectCode.substr(5, 2);
    if (!this.utilService.validateProjectCode(codWithMask)) {
      this.message.showErrorMessage("Codigo inválido, Insira conforme o padrão")
      this.form.controls['projectCode'].reset('');
    }
  }


  onRemoveFocusInput() { //check HAS BUG
    let elementRefById = document.getElementById('hideListDropdown');
    document.body.click();
  }

  onSelectComments(event) : string {
    if(event == '') {
      this.form.controls['comments'].setValue(event);
      if(!this.form.controls['comments'].valid) {
        return this.styleComments =  'error'
      }
    }

    this.handleFormChanges('changing_value');
    return this.styleComments = ' '
  }

}
