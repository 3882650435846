<div class="request_review__modal">
  <app-input-textarea
    [id]="'input-text-area-detail'"
    [name]="'Detalhamento'"
    class="final_comments_input_container"
    placeholder="Descreva os detalhes sobre os motivos"
    errorMessage=""
    [status]="' '"
    [styleResize]="'none'"
    [required]="true"
    rows="15"
    [controls]="form.get('details')"
    (changeValue)="onChangeDetails($event)">
  </app-input-textarea>

  <section class="request_review__actions">
    <app-button-icon-secondary 
      text="Cancelar" 
      (click)="closeModal()">
    </app-button-icon-secondary>

    <app-button-icon-primary 
      *ngIf="!requestReview"
      text="Solicitar"
      (click)="createReviewRequest()">
    </app-button-icon-primary>

    <app-button-icon-primary 
      *ngIf="requestReview"
      text="Editar"
      (click)="updateReviewRequest()">
    </app-button-icon-primary>
  </section>
</div>