import { Component, EventEmitter, OnChanges, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScopeChangeService } from '../../../service/scope-change/scope-change.service';
import { ScopeChangeResponse } from '../../../interface/scope-change-request';
import { take } from 'rxjs';

@Component({
  selector: 'app-detail-about-request-container',
  templateUrl: './detail-about-request-container.component.html',
  styleUrls: ['./detail-about-request-container.component.scss']
})
export class DetailAboutRequestContainerComponent implements OnInit, OnChanges {
  @Output() handleFormChanges = new EventEmitter<any>();
  @Input() scopeChangeId: number;
  @Input() checkValidateForm: boolean = false;

  scopeChange: ScopeChangeResponse | null;

  form: FormGroup;

  scopeChangeList: Array<{id: number; description: string}> = [];
  scopeChangeListSelected: Array<{id: number; description: string}> = []

  constructor(
    private readonly formBuilder: FormBuilder,
    private scopeChangeService: ScopeChangeService
  ) { }

  ngOnInit(): void {
    if (this.scopeChangeId) {
      this.getScopeChangeById(this.scopeChangeId);
    }
    this.onInitForm();
    this.getScopeChangeList();
  }

  ngOnChanges(): void {
    this.form?.controls['scopeCategory'].setValue(this.scopeChangeListSelected);
  }

  onInitForm(): void {
    this.form = this.formBuilder.group({
      scopeCategory: [this.scopeChange ? this.scopeChange.scopeChangeReasons : null, Validators.required],
      descriptionDetail: [this.scopeChange ? this.scopeChange.commentsAboutChange : null, Validators.required]
    });
    let scopeChangeListIds = this.scopeChangeList.map(el => el.id);
    this.scopeChange?.scopeChangeReasons.filter(el => {
      if (!scopeChangeListIds.includes(el.id)) this.scopeChangeList.push(el)
    })
    this.handleFormChanges.emit(this.form);
  }

  getScopeChangeById(scopeChangeId: number) {
    this.scopeChangeService.getScopeChange(scopeChangeId).subscribe({
      next: (value) => {
        this.scopeChange = value;
        this.scopeChangeListSelected = value.scopeChangeReasons;
        this.onInitForm();
      }
    });
  }

  onHandleFormChanges(): void {
    this.handleFormChanges.emit(this.form);
  }

  checkInputForm(controlName: string): string {
    if (!this.form.controls[controlName].valid  && this.checkValidateForm) {
      return 'error'
    }
    return ' '
  }

  onChangeScopeCategory(array: any[]) {
    this.scopeChangeListSelected = array;
    this.getScopeChangeList();

    if (array.length > 0) {
      this.scopeChangeList.push(array[array.length -1]);
    }
    this.onHandleFormChanges();
  }

  onChangeDescriptionDetail(event: string): void {
    this.form.controls['descriptionDetail'].setValue(event);
    this.onHandleFormChanges();
  }

  private getScopeChangeList(): void {
    this.scopeChangeService.getScopeCategory()
    .pipe(take(1))
    .subscribe({
      next: (response) => {
        this.scopeChangeList = response;
      },
      error: (err) => {}
    })
  }
}
