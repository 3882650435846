import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {ProjectsV2Service} from "../../../shared/services/projects-v2.service";
import {toNumber} from "ng-zorro-antd/core/util";
import { DisciplineAssessmentService } from 'src/app/shared/services/discipline-assessment.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeChangeDisciplineResolver  {

  constructor(private projectService: ProjectsV2Service, private discipline: DisciplineAssessmentService ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    //forkJoin if no dependency == switchMap if has dependencies
    const scopeChangeId = "" + route.paramMap.get("scopeId");
    const disciplineId = route.paramMap.get("id");

    if(disciplineId) {

      return this.projectService.getScopeChange(+scopeChangeId).pipe(
        switchMap(scopeChangeData => {
          if(!scopeChangeData){
            return of();
          }
          return this.projectService.projectCache(scopeChangeData.projectId).pipe(
            switchMap(projectData => {
              return this.discipline.getDisciplineAssessmentById(+disciplineId).pipe(
                switchMap(disciplineAssessment => {
                  return of({
                    projectData: projectData,
                    scopeChangeData: scopeChangeData,
                    disciplineAssessment: disciplineAssessment
                  })
                })
              )
            })
          )
        })
      )
    }
    else
      return of();
  }
}
