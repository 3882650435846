import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {ProjectsV2Service} from "../../../shared/services/projects-v2.service";
import {toNumber} from "ng-zorro-antd/core/util";
import { DisciplineAssessmentService } from 'src/app/shared/services/discipline-assessment.service';
import { ScopeChangeService } from '../service/scope-change/scope-change.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeChangeAnalysisResolver  {constructor(private projectService: ProjectsV2Service, private scopeService: ScopeChangeService, ) {
}

resolve(route: ActivatedRouteSnapshot): Observable<any> {
  //forkJoin if no dependency == switchMap if has dependencies
  const scopeChangeId = route.paramMap.get("id");

  if(scopeChangeId) {

    return this.projectService.getScopeChange(+scopeChangeId).pipe(
      switchMap(scopeChangeData => {
        if(!scopeChangeData){
          return of();
        }
        return this.projectService.projectCache(scopeChangeData.projectId).pipe(
          switchMap(projectData => {
            return of({
              projectData: projectData,
              scopeChangeData: scopeChangeData,
            })
          })
        )
      })
    )
  }
  else
    return of();
}
}
