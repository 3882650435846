@if (!_feedback && showModal) {
<div class="app-modal-confirm-send-email">
    <div nz-row class="container__row">


        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="header__content">
            <img src="/assets/icons/icon_close_green24.svg" (click)="onCloseModal()">
        </div>

        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="body__content">
            <div>
                <img src="/assets/icons/arrow_grey-icon24.svg">
            </div>

            <div class="text">
                <span> Antes de finalizar deseja enviar esta conversa por email? </span>
            </div>
        </div>

        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="footer__content">
            <app-button-icon-secondary [text]="'Não'" [customClassBtn]="'padding: 0 16px;'" (click)="onCloseModalButton()"></app-button-icon-secondary>
            <app-button-icon-primary [text]="'Sim'" [customClassBtn]="'padding: 0 16px;'" (click)="onSendEmail()"></app-button-icon-primary>
        </div>
    </div>
</div>
}

@if (_feedback && showFeedback)
{
<div class="feedback__email">
    <span>{{ _feedback.message }}</span>
    <img src="/assets/icons/icon_close8.svg" (click)="onCloseModalFeedback()">
</div>
}