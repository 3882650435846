import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CHAT_AI, CHAT_AI_EMAIL, GET_ALL_CHATS } from "../../projects/constants/apiEndpointsConstants";
import { IGetAllChats } from "../interface/historic-chats-view";


@Injectable({
  providedIn: 'root'
})
export class ChatAiService {
  constructor(private readonly http: HttpClient) { }

  callChatAiEndpoint(body: any, endpointRoute: string): Observable<any> {
    const request = {
      jsonStringBody: JSON.stringify(body),
      chatEndpoint: endpointRoute
    }
    
    return this.http.post<any[]>(`${CHAT_AI}`, request);
  }

  callChatAiEmail(params: {chatId: string; title: string}): Observable<any> {
    return this.http.post<any>(`${CHAT_AI_EMAIL}`, params);
  }

  getAllChats(): Observable<IGetAllChats> {
    return this.http.get<IGetAllChats>(`${GET_ALL_CHATS}`);
  }

}