import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScopeChangeResponse } from '../../../interface/scope-change-request';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScopeChangeService } from '../../../service/scope-change/scope-change.service';
import { debounceTime, take } from 'rxjs';
import { ScopeChangeImpactsResponse } from '../../../interface/scope-change-publish-final';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { UploadFilesComponent } from 'src/app/shared/components/upload-files/upload-files.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-publish-final-form',
  templateUrl: './publish-final-form.component.html',
  styleUrls: ['./publish-final-form.component.scss']
})
export class PublishFinalFormComponent implements OnInit, AfterViewInit {

  @ViewChild(UploadFilesComponent) uploadFiles: UploadFilesComponent;

  @Output() handleFormChanges = new EventEmitter<any>();
  @Output() handleUploadFiles = new EventEmitter<NzUploadFile[]>();
  @Output() handleScopeChange = new EventEmitter<ScopeChangeResponse>();

  @Input() scopeChangeId: number;
  @Input() checkValidateForm: boolean = false;
  @Input() idScopeChange: number;
  
  scopeChange: ScopeChangeResponse | null;
  estimateList: Array<{value: string, label: string}>
  form: FormGroup;

  scopeChangeImpactsList: ScopeChangeImpactsResponse[] = [];
  scopeChangeImpactsListSelected: ScopeChangeImpactsResponse[] = []

  constructor(
    private readonly formBuilder: FormBuilder,
    private scopeChangeService: ScopeChangeService,
  ) { }

  ngOnInit(): void {
    this.onInitForm();
    this.getAssociateImpact();
    this.getEstimateList();
    this.getScopeChange();
  }

  ngAfterViewInit(): void {
    this.uploadFiles?.filesEmit?.subscribe((emit) => this.handleUploadFiles.emit(emit));
  }

  onInitForm(): void {
    this.form = this.formBuilder.group({
      scopeChangeImpacts: [null, Validators.required],
      impactDescription: [null, [Validators.required, Validators.maxLength(2000)]],
      impactEstimated: [null, Validators.required]
    });
    this.handleFormChanges.emit(this.form);
  }

  onHandleFormChanges(): void {
    this.checkValidateForm = false;
    this.handleFormChanges.emit(this.form)
  }

  onChangeScopeChangeImpacts(array: any[]) {
    this.scopeChangeImpactsListSelected = array;
    this.getAssociateImpact();
    if (array.length > 0) {
      this.scopeChangeImpactsList.push(array[array.length -1]); 
    }
    this.onHandleFormChanges();
  }

  onChangeScopeImpactEstimated(event: any): void {
    this.form.controls['impactEstimated'].setValue(event);
    this.onHandleFormChanges();
  }

  onChangeDescriptionDetail(event: string): void {
    this.form.controls['impactDescription'].setValue(event);
    this.onHandleFormChanges();
  }

  checkInputForm(controlName: string): string {
    if (!this.form.controls[controlName].valid && this.checkValidateForm) {
      return 'error'
    }
    return ' '
  }

  private getAssociateImpact() {
    this.scopeChangeService.getAssociateImpact()
    .subscribe({
      next: (response) => {
        this.scopeChangeImpactsList = response;
      }
    })
  }

  private getEstimateList(): void {
    this.estimateList = this.scopeChangeService.getEstimateList();
  }

  private getScopeChange(): void {
    if (this.idScopeChange) {
      this.scopeChangeService.getScopeChange(this.idScopeChange)
        .subscribe((response) => {
          this.form.controls['scopeChangeImpacts'].setValue(response.scopeChangeImpacts);
          this.form.controls['impactEstimated'].setValue(response.impactEstimated);
          this.form.controls['impactDescription'].setValue(response.impactDescription);
          this.onHandleFormChanges();
          this.handleScopeChange.emit(response);
        })
    }
  }

}

