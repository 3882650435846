import { Component, Input, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzModalRef } from "ng-zorro-antd/modal";
import { MessageService } from "src/app/shared/common/services/message.service";
import { ScopeChangeService } from "../../service/scope-change/scope-change.service";
import { ScopeChangeUpdateReviewRequest } from "../../interface/scope-change-request";

@Component({
  selector: 'app-request-review-modal',
  templateUrl: './request-review-modal.component.html',
  styleUrls: ['./request-review-modal.component.scss']
})
export class RequestReviewModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() scopeChangeId: number;
  @Input() requestReview: {id: number, details: string};
  form: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private service: ScopeChangeService
  ) {}

  ngOnInit(): void {
   this.initForm();
   this.isUpdate();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      details: [null, Validators.required],
    });
  }

  isUpdate(): void {
    if (this.requestReview) {
      this.form.controls['details'].setValue(this.requestReview.details);
    }
  }

  onChangeDetails(event: string): void {
    this.form.controls['details'].setValue(event);
  }

  createReviewRequest(): void {
    if (!this.form.valid) {
      this.messageService.showErrorMessage("Preencha o campo obrigatório");
      return;
    }

    const payload = {
      scopeChangeId: this.scopeChangeId,
      comments: this.form.controls['details'].value
    }

    this.service.createScopeChangeReview(payload).subscribe({
      next: () => {
        this.messageService.showSucessMessage("Solicitação de revisão criada com sucesso")
        this.closeModal(true);
      },
      error: (error) => {
        if (error.error.errors !== undefined) { 
          this.messageService.showErrorMessage(error.error.errors.details)
        } else if (error.error.message !== undefined) {
          this.messageService.showErrorMessage(error.error.message)
        } else {
          this.messageService.showErrorMessage("Erro ao solicitar revisão")
        }
      }
    })
  }

  updateReviewRequest(): void {
    if (!this.form.valid && this.requestReview) {
      this.messageService.showErrorMessage("Preencha o campo obrigatório");
      return;
    }

    const payload: ScopeChangeUpdateReviewRequest = {
      id: this.requestReview.id,
      description: this.form.controls['details'].value
    }

    this.service.updateScopeChangeReview(payload).subscribe({
      next: () => {
        this.messageService.showSucessMessage("Edição da revisão atualizada com sucesso")
        this.closeModal(true);
      },
      error: () => {
        this.messageService.showErrorMessage("Erro ao editar a revisão")
      }
    })
  }

  closeModal(success: boolean = false) {
    this.#modal.destroy(success);
  }
}