import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';

// Zorro
import {toNumber} from "ng-zorro-antd/core/util";

// Service
import {ProjectsV2Service} from "../../../shared/services/projects-v2.service";
import { ScopeChangeService } from '../service/scope-change/scope-change.service';
import {ProjectByIdResponseDto} from "../../../shared/dto/project-by-id-response.dto";


@Injectable({
  providedIn: 'root'
})
export class ScopeChangeResolver  {

  constructor(
    private projectV2Service: ProjectsV2Service,
    private scopeChangeService: ScopeChangeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectByIdResponseDto> {
    const id = route.parent?.paramMap.get("projectId");
    if(id)
      return this.projectV2Service.getProjectByProjectId(toNumber(id));
    else
      return of();
  }

}
