import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-final-opinion-resume',
  templateUrl: './final-opinion-resume.component.html',
  styleUrls: ['./final-opinion-resume.component.scss']
})
export class FinalOpinionResumeComponent implements OnInit {
  @Input() resumeData?;

  contentRows:any[];

  constructor() { }

  ngOnInit() {
    let impactDescription = 'Até 6 meses';
    switch(this.resumeData.impactEstimated){
      case 'A3MES':
        impactDescription = 'Até 3 meses';
        break;
        case 'A6MES':
        impactDescription = 'Até 6 meses';
        break;
        case 'E6M1A':
        impactDescription = 'Entre 6 meses e 1 ano';
        break;
        case 'E1A2A':
        impactDescription = 'Entre 1 e 2 anos';
        break;
        case 'E2A5A':
        impactDescription = 'Entre 2 e 5 anos';
        break;
    }
    const associatedImpacts = this.resumeData.scopeChangeImpacts.map(impact => {
      return impact.description
    }).sort((a, b) => a.localeCompare(b))

    this.contentRows = [{
      title: "Estimativa de tempo",
      content: [impactDescription], //if array - bullets, if string plain text
      contentType: "plainText",
      hint: ""
    },{
      title: "Impactos associados",
      content: associatedImpacts,
      contentType: "bulletList",
      hint: ""
    },{
      title: "Descrição dos impactos",
      content: [this.resumeData.impactDescription],
      contentType: "plainText",
      hint: ""
    }]
  }

}
