import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-action-bar',
  standalone: false,
  templateUrl: './action-bar.component.html',
  styleUrl: './action-bar.component.scss'
})
export class ActionBarComponent {
  @Output() action: EventEmitter<string> = new EventEmitter<string>();

  minimize = false;

  callAction(action: string):void {

    this.action.emit(action);

    if(action === 'maximize'){this.minimize = !this.minimize;}

  }
}
