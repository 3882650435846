<div class="app-card-title">
    <div class="icon">
        <img src="assets/icons/icon_ai_particles_dark_purple24.svg">
    </div>
    <div class="title">
        <input *ngIf="isEditing"
            id="focusInputTitle"
            type="text" 
            pInputText 
            [(ngModel)]="configCardTitle.title" 
            (focusout)="onFocusOut()"
            (keydown)="onKeyDown($event)"/>
        
        <h1 *ngIf="!isEditing"
            [class.conversation]="!configCardTitle.subtitle" 
            [class.cursor-pointer]="configCardTitle.editable"
            (click)="onEditTitle()">{{configCardTitle.title}}
        </h1>
        <p class="subtitle">{{configCardTitle.subtitle}}</p>
    </div>
</div>
