<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
  <section class="app-final-opinion-me sl__simpleLayout">
    <div class="sl__simpleLayout__card">
      <header class="sl__simpleLayout__card__header">
        <h2 class="sl__simpleLayout__card__header__title">
          <!-- Sobre a requisição -->
          {{
            "projects.scope-change.final-opinion.title-licensing-final-opinion"
              | translate
          }}
        </h2>
      </header>

      <div *ngIf="opinionStatus === 'PENDING'">
        <app-badge-chips
          badgeColor="warning"
          badgeText="projects.scope-change.final-opinion.pending-status"
          badgeIcon="icon_warning_neutral"
        ></app-badge-chips>
      </div>

      <div
        *ngIf="opinionStatus !== 'PENDING' && opinionStatus !== 'DONE'"
        class="action-final-opinion"
      >
        {{ "projects.scope-change.final-opinion.in-progress" | translate }}
        <button class="btnFinalOpnion"  *ngIf="opinionStatus === 'CONSOLIDATE'">
          {{
            "projects.scope-change.final-opinion.consolidate-final-opinion"
              | translate
          }}
        </button>
        <button class="btnFinalOpnion" *ngIf="editFinalOpnion && opinionStatus === 'DRAFT' && !this.isClosedOrCancelled"  (click)="goToFinalOpinion()">
          {{
            "projects.scope-change.final-opinion.continue-final-opinion"
              | translate
          }}
        </button>
        <button class="btnFinalOpnion" *ngIf="editFinalOpnion && opinionStatus === 'EDIT' && !this.isClosedOrCancelled" (click)="goToFinalOpinion()">
          {{
            "projects.scope-change.final-opinion.edit-final-opinion" | translate
          }}
        </button>
      </div>

      <div *ngIf="opinionStatus === 'DONE'">
        <app-final-opinion-resume
          [resumeData]="scopeData"
        ></app-final-opinion-resume>
      </div>
    </div>
  </section>
</div>
