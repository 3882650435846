<div class="app-action-bar">
  <a class="icon back" id="btn-back-chatList" (click)="callAction('back')">Voltar</a>
  <div class="right-group">
    <a class="icon maximize" (click)="callAction('maximize')" [class.minimize]="minimize"
      [appTooltip]="(minimize ? ('chat-ia.action-bar.minimize' | translate) : ('chat-ia.action-bar.maximize' | translate))" tooltipArrowPosition="top" tooltipArrowAlignment="right"></a>

    <a class="icon email" (click)="callAction('email')" [appTooltip]="'chat-ia.action-bar.email' | translate"
      tooltipWidth="150px" tooltipArrowPosition="top" tooltipArrowAlignment="right"></a>

    <a class="icon new-chat" (click)="callAction('new-chat')" [appTooltip]="'chat-ia.action-bar.new-chat' | translate" tooltipWidth="120px"
      tooltipArrowPosition="top" tooltipArrowAlignment="right"></a>
  </div>
</div>
