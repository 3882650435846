import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpLoaderFactory } from '../app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ZORRO MODULES
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

// PRIMENG MODULES
import { OverlayPanelModule } from 'primeng/overlaypanel';

// SMART LICENSING MODULES
import { InputsLicensingModule } from './components/inputs/inputs-licensing.module';
import { ButtonsLicensingModule } from './components/buttons/buttons-licensing.module';
import { SelectsLicensingModule } from './components/selects/selects-licensing.module';

// SMART LICENSING COMPONENTS
import { BadgeComponent } from './components/badge/badge.component';
import { SVGIconComponent } from './components/icons/svg-icon/svg-icon.component';
import { BadgeChipsComponent } from './components/badge-chips/badge-chips.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ShortTextHintComponent } from './components/text-shortner/short-text-hint.component';
import { UploadFilesHorzComponent } from './components/upload-files-horz/upload-files-horz.component';
import { InformationBannerComponent } from './components/information-banner/information-banner.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ProjectResumeInfosComponent } from './components/project-resume-infos/project-resume-infos.component';
import { GenericResumeSidebarComponent } from './components/generic-resume-sidebar/generic-resume-sidebar.component';
import { GenericNonEditableTableComponent } from './components/generic-non-editable-table/generic-non-editable-table.component';
import { AnalysisAboutComponent } from '../modules/scope-change/components/scope-change-analysis-view/analysis-about/analysis-about.component';
import { RequestingAnalysisStatusComponent } from '../modules/projects-v2/project-v2-suspension/component/request-analysis-status-me/request-analysis-status-me.component';
import { ResumeScopeChangeRequestContainerComponent } from '../modules/scope-change/components/scope-change-form/resume-scope-change-request-container/resume-scope-change-request-container.component';
import { ActivitiesContainerComponent } from '../modules/projects/projects-licensing/components/activities/activities-container/activities-container.component';
import { ActivitiesListComponent } from '../modules/projects/projects-licensing/components/activities/activities-list/activities-list.component';
import { CommunicationListComponent } from '../modules/communications/communication-list/communication-list.component';
import { ButtonOptionsDotsComponent } from './components/buttons/button-options-dots/button-options-dots.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { BreadcrumbLocalComponent } from '../core/shell/breadcrumb-local/breadcrumb-local.component';
import { LoadingBarComponent } from './components/loading-modal/loading-bar/loading-bar.component'; 

const MODULES = [
  NzFormModule,
  CommonModule,
  NzInputModule,
  NzRadioModule,
  NzTableModule,
  NzUploadModule,
  NzSelectModule,
  NzButtonModule,
  NzDividerModule,
  NzToolTipModule,
  NzPopoverModule,
  NzCheckboxModule,
  NzDropDownModule,
  NzAutocompleteModule,
  InputsLicensingModule,
  ButtonsLicensingModule,
  SelectsLicensingModule,
  OverlayPanelModule,
];
const COMPONENTS = [
  BadgeComponent,
  SVGIconComponent,
  BadgeChipsComponent,
  UploadFilesComponent,
  AnalysisAboutComponent,
  UploadFilesHorzComponent,
  InformationBannerComponent,
  ConfirmationModalComponent,
  ProjectResumeInfosComponent,
  GenericResumeSidebarComponent,
  GenericNonEditableTableComponent,
  RequestingAnalysisStatusComponent,
  ResumeScopeChangeRequestContainerComponent,
  ActivitiesContainerComponent,
  ActivitiesListComponent,
  CommunicationListComponent,
  ButtonOptionsDotsComponent,
  DeleteConfirmationModalComponent,
  ShortTextHintComponent,
  BreadcrumbLocalComponent,
  LoadingBarComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, NzUploadModule],
  imports: [
    ...MODULES,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
