import { on } from '@ngrx/store';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IstatusModalSendEmail {
  closeModalButton: boolean;
  closeModal: boolean;
  sendEmail: boolean; 
}

export interface IfeedbackModalSendEmail {
  message: string;
}

@Component({
  selector: 'app-modal-confirm-send-email',
  templateUrl: './modal-confirm-send-email.component.html',
  styleUrl: './modal-confirm-send-email.component.scss'
})
export class ModalConfirmSendEmailComponent {

  @Input()
  set feedback(value: IfeedbackModalSendEmail) {
    this._feedback = value;
    if (this._feedback && this._feedback.message) {
      this.showFeedback = true;
      this.onFeedbackReceived();
    }
  }

  @Output() changeModal: EventEmitter<IstatusModalSendEmail> = new EventEmitter<IstatusModalSendEmail>();

  _feedback: IfeedbackModalSendEmail | null;
  statusModalSendEmail: IstatusModalSendEmail = { closeModal: false, sendEmail: false, closeModalButton: false };
  showFeedback = true;
  showModal = true;

  onCloseModal() {
    this.showModal = false;
    this.statusModalSendEmail.closeModal = true;
    this.statusModalSendEmail.closeModalButton = true;
    this.statusModalSendEmail.sendEmail = false;
    this.changeModal.emit(this.statusModalSendEmail);
  }

  onCloseModalButton() {
    this.statusModalSendEmail.closeModal = false;
    this.statusModalSendEmail.closeModalButton = true;
    this.statusModalSendEmail.sendEmail = false;
    this.changeModal.emit(this.statusModalSendEmail);
  }

  onSendEmail() {
    this.statusModalSendEmail.closeModal = false;
    this.statusModalSendEmail.closeModalButton = false;
    this.statusModalSendEmail.sendEmail = true;
    this.changeModal.emit(this.statusModalSendEmail);
  }

  onFeedbackReceived() {
    setTimeout(() => {
      this.showFeedback = false;
    }, 5000);
  }

  onCloseModalFeedback() {
    this.showFeedback = false;
  }

}
